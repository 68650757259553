import React from "react";
import KairuImage from "../../assets/images/KairuImage.png";

const KairuIcon = () => {
  return (
    <img
      src={KairuImage}
      alt="Bonzi Logo"
      width="50"
      height="50"
      style={{ pointerEvents: "none" }}
    />
  );
};

export default KairuIcon;
