import { useWindowDrag } from "../hooks/useWindowDrag";

const Window = ({ title, children, containerRef, isVisible, onClose }) => {
  const handleMouseDown = useWindowDrag(containerRef);

  if (!isVisible) return null;

  return (
    <div className="window container-window" ref={containerRef}>
      <div className="title-bar" onMouseDown={handleMouseDown}>
        <div className="title-bar-text">{title}</div>
        <div className="title-bar-controls">
          <button aria-label="Close" onClick={onClose}></button>
        </div>
      </div>
      {children}
    </div>
  );
};

export default Window;