import React from "react";
import folder from "../../assets/images/folderGrey.png";
const FolderIcon = () => {
  return (
    <img
      src={folder}
      alt="Folder Logo"
      width="40"
      height="40"
      style={{ pointerEvents: "none" }}
    />
  );
};

export default FolderIcon;