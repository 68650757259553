import React from "react";
import { makeStyles, } from "@material-ui/core";
import PropTypes from "prop-types";
import * as COLORS from "@material-ui/core/colors";

const useStyles = makeStyles((theme) => ({
  button: {
    width: "100%",
    paddingTop: "15px",
    paddingBottom: 0,
    background: "none",
    "&:hover, &$focusVisible": {
      backgroundColor: COLORS.grey[900],
    },
  },
  coinName: {
    opacity: 0.5,
  },
}));

CoinButton.propTypes = {
  coinLogo: PropTypes.any.isRequired,
  coinName: PropTypes.string.isRequired,
  coinAbbr: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default function CoinButton(props) {
  const {coinLogo, coinName, coinAbbr, onClick, ...other } = props;
  const classes = useStyles();

  return (
    
    <button className={classes.button} onClick={onClick}>
      <div>
        <p>
          <img 
          src={coinLogo}
          alt={`${coinName} logo`} 
          style={{ maxWidth: '30px', maxHeight: '30px', marginRight: '10px' }} 
          />
          {coinAbbr}
        </p>
        <p className={classes.coinName}>
          {coinName}
        </p>
      </div>
    </button>
  );
}
