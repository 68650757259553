import React from "react";
import LimeIcon from "../Components/Icons/LimeIcon";
import PowershellIcon from "../Components/Icons/PowershellIcon";
import ClippyIcon from "../Components/Icons/ClippyIcon";
import NinjaIcon from "../Components/Icons/NinjaIcon";
import MinesweeperIcon from "../Components/Icons/MinesweeperIcon";
import RoverIcon from "../Components/Icons/RoverIcon";
import DegenIcon from "../Components/Icons/DegenIcon";
import BonziIcon from "./Icons/BonziIcon";
import KairuIcon from "./Icons/KairuIcon";

const iconsList = [
  {
    label: "Degen Analytics",
    link: "https://degenads.com/vista/",
    icon: <DegenIcon />,
  },
  {
    label: "Clippy Helper",
    link: "https://vistaclippy.xyz/",
    icon: <ClippyIcon />,
  },
  {
    label: "Bonzi",
    link: "https://bonzivista.com/",
    icon: <BonziIcon />,
  },
  {
    label: "Lime Audio",
    link: "https://lime.audio/",
    icon: <LimeIcon />,
  },
  {
    label: "Powershell",
    link: "https://powershelltoken.com/",
    icon: <PowershellIcon />,
  },
  {
    label: "Ninja",
    link: "https://www.ninjacat.xyz/",
    icon: <NinjaIcon />,
  },
  {
    label: "Mine Sweeper",
    link: "https://www.mine-sweeper.fun/",
    icon: <MinesweeperIcon />,
  },
  {
    label: "Roverswap",
    link: "https://www.vistarover.xyz/",
    icon: <RoverIcon />,
  },
  {
    label: "Kairu-vista",
    link: "https://kairu-vista.xyz/",
    icon: <KairuIcon />,
  },
];

const IconList = () => {
  return (
    <div
      style={{
        maxHeight: "400px",
        overflowY: "scroll",
        scrollbarWidth: "none",
        msOverflowStyle: "none",
      }}
    >
      <style>
        {`
          ::-webkit-scrollbar {
            display: none;
          }
        `}
      </style>
      {iconsList.map((item, index) => (
        <div key={index} style={{ marginBottom: "10px", marginLeft: "20px" }}>
          <a
            href={item.link}
            target="_blank"
            rel="noopener noreferrer"
            style={{
              textDecoration: "none", // Remove the underline from the link
            }}
          >
            <span>{item.icon}</span>
            <span
              style={{
                marginLeft: "10px",
                textDecoration: "none",
                color: "white",
              }}
            >
              {item.label}
            </span>
          </a>
        </div>
      ))}
    </div>
  );
};

export default IconList;