export const MenuItemsLocker = [
  {
    title: "Vista",
    url: "/lockvista",
    cName: "nav-links",
  },
  {
    title: "Hardlock",
    url: "/locker",
    cName: "nav-links",
  },
  {
    title: " ",
    url: "/rewardsLocker",
    cName: "nav-links",
  },
];
