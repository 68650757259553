import { Contract, ethers } from "ethers";
import * as chains from "./constants/chains";

const ROUTER = require("./build/UniswapV2Router02.json"); 
const ERC20 = require("./build/ERC20.json"); //DO NOT CHANGE Erc20 token not lp
const FACTORY = require("./build/IUniswapV2Factory.json");
const PAIR = require("./build/IUniswapV2Pair.json"); 
const HARDLOCK = require("./build/hardlock.json");
const HARDLOCK_VISTA = require("./build/hardlock_vista.json")
const HARDSTAKE = require("./build/hardstake.json");
const TOKEN_FACTORY = require("./build/TokenFactory.json");

export function getProvider() {
  return new ethers.providers.Web3Provider(window.ethereum);
}

export function getSigner(provider) {
  return provider.getSigner();
}

export async function getNetwork(provider) {
  const network = await provider.getNetwork();
  return network.chainId;
}

export function getRouter(address, signer) {
  return new Contract(address.toString(), ROUTER.abi, signer);
}

export async function checkNetwork(provider) {
  const chainId = getNetwork(provider);
  if (chains.networks.includes(chainId)) {
    return true;
  }
  return false;
}

export function getWeth(address, signer) {
  return new Contract(address, ERC20.abi, signer);
}

export function getFactory(address, signer) {
  return new Contract(address, FACTORY.abi, signer);
}

export async function getAccount() {
  const accounts = await window.ethereum.request({
    method: "eth_requestAccounts",
  });

  return accounts[0];
}

//=============== SWAP (ROUTER TAB) =================

export function doesTokenExist(address, signer) {
  try {
    return new Contract(address, ERC20.abi, signer);
  } catch (err) {
    console.log("token exist err?: ", err);
    return false;
  }
}

export async function swapTokens( //add slippage uint, //need two new components Slippage text and Fee button EIP1559??
  address1, //ESTIMATE GAS???
  address2,
  amount,
  routerContract,
  accountAddress,
  signer,
  factory,
  slippage
) {
  accountAddress = await signer.getAddress();

  const tokens = [address1, address2];
  const time = Math.floor(Date.now() / 1000) + 200000;
  const deadline = ethers.BigNumber.from(time);

  const token1 = new Contract(address1, ERC20.abi, signer);
  const tokenDecimals = await getDecimals(token1);

  const amountIn = ethers.utils.parseUnits(amount, tokenDecimals);
  const amountOut = await routerContract.callStatic.getAmountsOut(
    amountIn,
    tokens
  );

  const wethAddress = await routerContract.WETH();

  const pairAddress = await factory.getPair(address1, address2);
  const pairContract = new Contract(pairAddress, PAIR.abi, signer);

  if (address1 === wethAddress) {
    //buy
    let liqFee = await routerContract.usdcToEth(
      await pairContract.buyTotalFee()
    );

    /* global BigInt */

    console.log(Math.round(amountOut[1] * (1 - slippage / 100)));
    console.log(Math.round(Number(liqFee) + Number(amountIn)));
    await routerContract.swapExactETHForTokensSupportingFeeOnTransferTokens(
      BigInt(Math.round(amountOut[1] * (1 - slippage / 100))), //AmountoutMin: amountOut[1](1-slippage)
      tokens,
      accountAddress,
      deadline,
      { value: BigInt(Math.round(Number(liqFee) + Number(amountIn))) } //.toString()
    );
  } else if (address2 === wethAddress) {
    //sell
    const allowance1 = await token1.allowance(
      accountAddress,
      routerContract.address
    );
    console.log("allowance", allowance1.toString());
    if (Number(allowance1) < amountIn) {
      console.log("approving...");
      let tx = await token1.approve(
        routerContract.address,
        BigInt(amountIn * 2)
      );
      await tx.wait();
    }
    let sellfee = await pairContract.sellTotalFee();
    let liqFee = await routerContract.usdcToEth(sellfee); //totalSellFee actually
    await routerContract.swapExactTokensForETHSupportingFeeOnTransferTokens(
      BigInt(Number(amountIn)), //.toString(),
      BigInt(Math.round(amountOut[1] * (1 - slippage / 100))), //.toString(), //AmoutOutmin: amountOut[1](1-slippage)
      tokens,
      accountAddress.toString(),
      deadline,
      { value: BigInt(Math.round(Number(liqFee) * 1.03)) } //added feature , Number(liqFee).toString()?
    );
  }

  //Missing High priority: swapExactETHForTokensSupportingFeeOnTransferTokens
  //                       swapExactTokensForETHSupportingFeeOnTransferTokens

  //Missing SwapExactTokensForETH
  //        SwapExactETHForTokens
}

export async function getAmountOut(
  address1,
  address2,
  amountIn,
  routerContract,
  signer
) {
  try {
    const token1 = new Contract(address1, ERC20.abi, signer);
    const token1Decimals = await getDecimals(token1);
    const token2 = new Contract(address2, ERC20.abi, signer);
    const token2Decimals = await getDecimals(token2);
    const values_out = await routerContract.getAmountsOut(
      ethers.utils.parseUnits(String(amountIn), token1Decimals),
      [address1, address2]
    );
    const amount_out = values_out[1] * 10 ** -token2Decimals;
    return Number(amount_out);
  } catch {
    return false;
  }
}

export async function fetchReserves(address1, address2, pair, signer) {
  try {
    // Get decimals for each coin
    const coin1 = new Contract(address1, ERC20.abi, signer);
    const coin2 = new Contract(address2, ERC20.abi, signer);

    const coin1Decimals = await getDecimals(coin1);
    const coin2Decimals = await getDecimals(coin2);

    // Get reserves
    const reservesRaw = await pair.getReserves();

    // Put the results in the right order
    const results = [
      (await pair.token0()) === address1 ? reservesRaw[0] : reservesRaw[1],
      (await pair.token1()) === address2 ? reservesRaw[1] : reservesRaw[0],
    ];

    // Scale each to the right decimal place
    return [
      results[0] * 10 ** -coin1Decimals,
      results[1] * 10 ** -coin2Decimals,
    ];
  } catch (err) {
    console.log("error!");
    console.log(err);
    return [0, 0];
  }
}

export async function getReserves(
  address1,
  address2,
  factory,
  signer,
  accountAddress
) {
  accountAddress = await signer.getAddress();
  try {
    const pairAddress = await factory.getPair(address1, address2);
    const pair = new Contract(pairAddress, PAIR.abi, signer);

    if (pairAddress !== "0x0000000000000000000000000000000000000000") {
      const reservesRaw = await fetchReserves(address1, address2, pair, signer);
      const liquidityTokens_BN = await pair.balanceOf(accountAddress);
      const liquidityTokens = Number(
        ethers.utils.formatEther(liquidityTokens_BN)
      );

      return [
        reservesRaw[0].toPrecision(6),
        reservesRaw[1].toPrecision(6),
        liquidityTokens,
      ];
    } else {
      console.log("no reserves yet");
      return [0, 0, 0];
    }
  } catch (err) {
    console.log("error!");
    console.log(err);
    return [0, 0, 0];
  }
}

// =============== LP REWARDS (ROUTER TAB) ==============

export async function getStats(
  address1,
  routerContract, //props.network.router
  factory, //props.network.factory
  signer
) {
  try {
    const address2 = await routerContract.WETH();
    const pairAddress = await factory.getPair(address1, address2);
    const pairContract = new Contract(pairAddress, PAIR.abi, signer);
    const eth_price = (await routerContract.usdcToEth(1)) / 10 ** 18;

    if (pairAddress !== "0x0000000000000000000000000000000000000000") {
      let usrShare = (await pairContract.viewShare()).toString();
      let total = (await pairContract.totalCollected()).toString();
      let pool = (await pairContract.poolBalance()).toString();

      let share = (
        (Number(ethers.utils.formatEther(usrShare)) * 1) /
        eth_price
      ).toFixed(2);
      let totalCollected = (
        (Number(ethers.utils.formatEther(total)) * 1) /
        eth_price
      ).toFixed(2);
      let poolBalance = (
        (Number(ethers.utils.formatEther(pool)) * 1) /
        eth_price
      ).toFixed(2);
      //let lockTime = Number(ethers.utils.formatEther(await pairContract.lockTime()));

      return [
        share.toString(),
        totalCollected.toString(),
        poolBalance.toString(),
      ];
    } else {
      console.log("no rewards yet");
      return [0, 0, 0];
    }
  } catch (err) {
    console.log(err);
    return [0, 0, 0];
  }
}

export async function claimRewards(
  address1,
  routerContract, //props.network.router
  signer,
  factory //props.network.factory
) {
  const address2 = await routerContract.WETH();
  const pairAddress = await factory.getPair(address1, address2);
  const pairContract = new Contract(pairAddress, PAIR.abi, signer);
  if (pairAddress !== "0x0000000000000000000000000000000000000000") {
    await pairContract.claimShare();
  }
}

//============= UTILS ===========

function convertTimestampToDate(unixTimestamp) {
  // Create a new Date object with the Unix timestamp (in milliseconds)
  const date = new Date(unixTimestamp * 1000); // Multiply by 1000 to convert to milliseconds

  // Get day, month, and year
  const day = String(date.getDate()).padStart(2, "0"); // Add leading zero if necessary
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed, so add 1
  const year = date.getFullYear();

  // Format the date as DD/MM/YYYY
  return `${day}/${month}/${year}`;
}

export async function getDecimals(token) {
  const decimals = await token
    .decimals()
    .then((result) => {
      return result;
    })
    .catch((error) => {
      console.log("error setting to 18", error);
      return 18;
    });
  return decimals;
}

export async function getBalanceAndSymbol(
  accountAddress,
  address,
  provider,
  signer,
  weth_address,
  coins
) {
  accountAddress = await signer.getAddress();
  try {
    if (address === weth_address) {
      const balanceRaw = await provider.getBalance(accountAddress.toString());
      return {
        balance: ethers.utils.formatEther(balanceRaw),
        symbol: coins[0].abbr,
      };
    } else {
      const token = new Contract(address, ERC20.abi, signer);
      const tokenDecimals = await getDecimals(token);
      const balanceRaw = await token.balanceOf(accountAddress.toString());
      const symbol = await token.symbol();

      return {
        balance: balanceRaw * 10 ** -tokenDecimals,
        symbol: symbol,
      };
    }
  } catch (error) {
    console.log("The getBalanceAndSymbol function had an error!");
    console.log(error);
    return false;
  }
}

function convertUnixToTimeString(unixTimestamp) {
  let seconds = unixTimestamp;

  // Calculate hours
  const hours = Math.floor(seconds / 3600); // Get full hours
  seconds %= 3600; // Remainder for minutes

  // Calculate minutes
  const minutes = Math.floor(seconds / 60); // Get full minutes

  return `Hours: ${hours}, Minutes: ${minutes}`;
}

//============== ANALYTICS (GET METADATA FROM PAIR CONTRACT) NOT USED IN THE WEBSITE===============
export async function getMetadata(
  address1,
  routerContract, //props.network.router
  factory, //props.network.factory
  signer
) {
  try {
    const address2 = await routerContract.WETH();
    const pairAddress = await factory.getPair(address1, address2);
    const pairContract = new Contract(pairAddress, PAIR.abi, signer);

    //==================
    let metadata = await pairContract.fetchMetadata(); //website/logo/description/chat/social
    let buyFee = await pairContract.buyTotalFee();
    let sellFee = await pairContract.sellTotalFee();
    let total_rewards = await pairContract.totalCollected();
    let creator = await pairContract.creator();
    let creation = await pairContract.creation_time();
    let creation_date = convertTimestampToDate(creation);
    //==================
    let renounced = creator == 0x0000000000000000000000000000000000000000;
    //console.log('CREATION:', creation);
    const token = new Contract(address1, ERC20.abi, signer);
    const symbol = await token.symbol();
    const name = await token.name();
    const supply = (await token.totalSupply()) / 10 ** 18;

    const priceData = await getPriceData(
      address1,
      routerContract,
      pairContract,
      signer,
      supply
    );

    //now must return creation_date
    return {
      creation_date,
      metadata,
      symbol,
      name,
      supply,
      renounced,
      totalRewardsUSD: Number(total_rewards) / (priceData.eth_price * 10 ** 18),
      sellFee,
      buyFee,
      ...priceData,
    };
  } catch (err) {
    console.log(err);
    console.log("error fetch metadata");
  }
}

async function getPriceData(
  address1,
  routerContract,
  pairContract,
  signer,
  supply
) {
  const coin1 = new Contract(address1, ERC20.abi, signer);
  const coin1Decimals = await coin1.decimals();
  const coin2Decimals = 18;

  let reservesRaw = await pairContract.getReserves();
  const results = [
    (await pairContract.token0()) === address1
      ? reservesRaw[0]
      : reservesRaw[1],
    (await pairContract.token1()) === (await routerContract.WETH())
      ? reservesRaw[1]
      : reservesRaw[0],
  ];

  const eth_price = (await routerContract.usdcToEth(1)) / 10 ** 18;
  let price = (
    (((results[1] * 10 ** -coin2Decimals) /
      (results[0] * 10 ** -coin1Decimals)) *
      1) /
    eth_price
  ).toFixed(5);

  let marketcap = price * supply;
  const total_liquidity = (
    results[0] * 10 ** -coin1Decimals * price +
    (results[1] * 10 ** -coin2Decimals * 1) / eth_price
  ).toFixed(2);

  return {
    price,
    marketcap,
    total_liquidity,
    eth_price,
  };
}

export async function getLivedata(address1, routerContract, factory, signer) {
  try {
    const address2 = await routerContract.WETH();
    const pairAddress = await factory.getPair(address1, address2);
    const pairContract = new Contract(pairAddress, PAIR.abi, signer);

    let total_rewards = await pairContract.totalCollected();

    const coin1 = new Contract(address1, ERC20.abi, signer);
    const coin1Decimals = await coin1.decimals();
    const coin2Decimals = 18;

    let reservesRaw = await pairContract.getReserves();
    const results = [
      (await pairContract.token0()) === address1
        ? reservesRaw[0]
        : reservesRaw[1],
      (await pairContract.token1()) === address2
        ? reservesRaw[1]
        : reservesRaw[0],
    ];
    const eth_price = (await routerContract.usdcToEth(1)) / 10 ** 18; //eth/$
    let price = (
      (((results[1] * 10 ** -coin2Decimals) /
        (results[0] * 10 ** -coin1Decimals)) *
        1) /
      eth_price
    ).toFixed(5);
    const token = new Contract(address1, ERC20.abi, signer);
    const supply = (await token.totalSupply()) / 10 ** 18;
    console.log("supply", supply);
    let marketcap = price * supply;
    const total_liquidity = (
      results[0] * 10 ** -coin1Decimals * price +
      (results[1] * 10 ** -coin2Decimals * 1) / eth_price
    ).toFixed(2);

    return {
      supply,
      price,
      totalRewardsUSD: Number(total_rewards) / (eth_price * 10 ** 18),
      marketcap,
      total_liquidity,
    };
  } catch (err) {
    console.log(err);
    console.log("error fetch metadata");
    return null; // or an appropriate error object
  }
}

export async function getFees(
  address1,
  routerContract, //props.network.router
  factory, //props.network.factory
  signer
) {
  try {
    const address2 = await routerContract.WETH();
    const pairAddress = await factory.getPair(address1, address2);
    const pairContract = new Contract(pairAddress, PAIR.abi, signer);

    if (pairAddress !== "0x0000000000000000000000000000000000000000") {
      let sellFee = (await pairContract.sellTotalFee()).toString();
      let buyFee = (await pairContract.buyTotalFee()).toString();

      return [buyFee, sellFee];
    } else {
      console.log("no rewards yet");
      return [0, 0];
    }
  } catch (err) {
    console.log(err);
    return [0, 0];
  }
}

//================ LOCK LP ======================
export async function lock (
  address1,
  amount,
  routerContract,
  signer
) {
  const network = await signer.provider.getNetwork();
  const chainId = network.chainId;
  let lockerAddress;
  switch (chainId) {
    case 1:
      lockerAddress = "0xF6B510928ab880507246CD6946b7F061Eb8A9C78";
      break;
    case 8453:
      lockerAddress = "0x3EdC8cda5610442a5708CDF5930DbE83C4173257"
      break;
    case 42161:
      lockerAddress = "0xC1a8De4D5eD59385218e957D7CeB2d41c7c74B26"
      break;
    default:
      lockerAddress = "0xF6B510928ab880507246CD6946b7F061Eb8A9C78"
  }

  let accountAddress = await signer.getAddress();
  const token = new Contract(address1, ERC20.abi, signer);
  const allowance1 = await token.allowance(
    accountAddress,
    routerContract.address
  );

  if (Number(allowance1) < amount*(10**18)) {
    let tx = await token.approve(
      routerContract.address,
      BigInt(amount*(10**18))
    );
    await tx.wait();
  }

  await routerContract.hardstake(lockerAddress, address1, BigInt(amount*(10**18)));
  
}

export async function lpvistalock (
  amount,
  routerContract,
  signer
) {
  const network = await signer.provider.getNetwork();
  const chainId = network.chainId;
  if (chainId == 1) {
    let accountAddress = await signer.getAddress();
    const lockAddress = "0x9099EF7f34dC1af0d27e49Dc5B604bcCC03dCB21";
    const VISTA_LP = "0xfdd05552F1377aA488AFed744c8024358AF02041";
    const token = new Contract(VISTA_LP, ERC20.abi, signer);
    const allowance1 = await token.allowance(
      accountAddress,
      routerContract.address
    );

    if (Number(allowance1) < amount*(10**18)) {
      let tx = await token.approve(
        routerContract.address,
        BigInt(amount*(10**18))
      );
      await tx.wait();
    }
    await routerContract.hardstake(lockAddress, VISTA_LP, BigInt(amount*(10**18)));
  } else {
    console.log("Can only stake VISTA-LP in the ETH network")
  }
}

export async function lpvistaunlock (
  amount,
  signer,
) {
  const network = await signer.provider.getNetwork();
  const chainId = network.chainId;
  if (chainId == 1) {
    const lockAddress = "0x9099EF7f34dC1af0d27e49Dc5B604bcCC03dCB21";
    const lockContract = new Contract(lockAddress, HARDSTAKE.abi, signer);
    await lockContract.withdraw(BigInt(amount*(10**18)));
  } else {
    console.log("Can only stake VISTA-LP in the ETH network")
  }
}

export async function lpvistastaker(signer) {
  const staker = await signer.getAddress();
  const provider = new ethers.providers.JsonRpcProvider('https://ethereum-rpc.publicnode.com');
  const privateKey = '0x93446dbfe2212b697dc281c7420d59ac9f613126487a66131c53450192757fe4';
  const signant = new ethers.Wallet(privateKey, provider);
  const lockAddress = "0x9099EF7f34dC1af0d27e49Dc5B604bcCC03dCB21";
  const lockContract = new Contract(lockAddress, HARDSTAKE.abi, signant);

  let [amountStaked, timeLeftToUnlock, currentShare] = await lockContract.getStakerInfo(staker);

  let amount = Number(amountStaked)/(10**18);
  let timeLeftFormatted = convertUnixToTimeString(timeLeftToUnlock).toString();

  return { amount, timeLeftFormatted, currentShare };
}

export async function claimRewardsLOCK(
  address1,
  signer,
) {
  const network = await signer.provider.getNetwork();
  const chainId = network.chainId;
  let lockerAddress;
  switch (chainId) {
    case 1:
      if(address1 == "0xfdd05552F1377aA488AFed744c8024358AF02041") {
        lockerAddress = "0x9099EF7f34dC1af0d27e49Dc5B604bcCC03dCB21";
      } else {
        lockerAddress = "0xF6B510928ab880507246CD6946b7F061Eb8A9C78";
      }
      break;
    case 8453:
      lockerAddress = "0x3EdC8cda5610442a5708CDF5930DbE83C4173257"
      break;
    case 42161:
      lockerAddress = "0xC1a8De4D5eD59385218e957D7CeB2d41c7c74B26"
      break;
    default:
      lockerAddress = "0xF6B510928ab880507246CD6946b7F061Eb8A9C78"
  }
  if (lockerAddress == "0x9099EF7f34dC1af0d27e49Dc5B604bcCC03dCB21") {
    const lockerContract = new Contract(lockerAddress, HARDLOCK_VISTA.abi, signer);
    await lockerContract.claimShare();
  } else {
    const lockerContract = new Contract(lockerAddress, HARDLOCK.abi, signer);
    await lockerContract.claimShare(address1);
  }
}

export async function getStatsLOCK(
  address1,
  routerContract, //props.network.router
  signer
) {
  try {
    const network = await signer.provider.getNetwork();
    const chainId = network.chainId;
    let lockerAddress;
    switch (chainId) {
      case 1:
      if(address1 == "0xfdd05552F1377aA488AFed744c8024358AF02041") {
        lockerAddress = "0x9099EF7f34dC1af0d27e49Dc5B604bcCC03dCB21";
      } else {
        lockerAddress = "0xF6B510928ab880507246CD6946b7F061Eb8A9C78";
      }
      break;
      case 8453:
        lockerAddress = "0x3EdC8cda5610442a5708CDF5930DbE83C4173257"
        break;
      case 42161:
        lockerAddress = "0xC1a8De4D5eD59385218e957D7CeB2d41c7c74B26"
        break;
      default:
        lockerAddress = "0xF6B510928ab880507246CD6946b7F061Eb8A9C78"
    }

    let lockerContract;
    if (lockerAddress == "0x9099EF7f34dC1af0d27e49Dc5B604bcCC03dCB21") {
      lockerContract = new Contract(lockerAddress, HARDLOCK_VISTA.abi, signer);
    } else {
      lockerContract = new Contract(lockerAddress, HARDLOCK.abi, signer);    
    }

    const eth_price = (await routerContract.usdcToEth(1)) / 10 ** 18;

      let usrShare;
      if (lockerAddress == "0x9099EF7f34dC1af0d27e49Dc5B604bcCC03dCB21") {
        usrShare = (await lockerContract.viewShare()).toString();
      } else {
        usrShare = (await lockerContract.viewShare(address1)).toString();
      }
      //let usrShare = (await lockerContract.viewShare(address1)).toString();
      let total = (await lockerContract.totalCollected()).toString();

      let share = (
        (Number(ethers.utils.formatEther(usrShare)) * 1) /
        eth_price
      ).toFixed(2);
      let totalCollected = (
        (Number(ethers.utils.formatEther(total)) * 1) /
        eth_price
      ).toFixed(2);
      let poolBalance = 0;

      return [
        share.toString(),
        totalCollected.toString(),
        poolBalance.toString(),
      ];
  } catch (err) {
    console.log(err);
    return [0, 0, 0];
  }
}

export async function getStakerInfoLOCK(address1, signer) {
  try {
    const staker = await signer.getAddress();
    const network = await signer.provider.getNetwork();
    const chainId = network.chainId;
    let lockerAddress;
    switch (chainId) {
      case 1:
        lockerAddress = "0xF6B510928ab880507246CD6946b7F061Eb8A9C78";
        break;
      case 8453:
        lockerAddress = "0x3EdC8cda5610442a5708CDF5930DbE83C4173257"
        break;
      case 42161:
        lockerAddress = "0xC1a8De4D5eD59385218e957D7CeB2d41c7c74B26"
        break;
      default:
        lockerAddress = "0xF6B510928ab880507246CD6946b7F061Eb8A9C78"
    }
    const lockerContract = new ethers.Contract(lockerAddress, HARDLOCK.abi, signer);

    const [amountStaked, currentShare] = await lockerContract.getStakerInfo(staker, address1);

    return { amountStaked, currentShare };
  } catch (error) {
    console.error("Error fetching staker info:", error);
    return { amountStaked: 0, currentShare: 0 }; // Return default values in case of error
  }
}

//============ HARDSTAKE ==================

export async function stake (
  amount,
  routerContract,
  signer
) {
  const network = await signer.provider.getNetwork();
  const chainId = network.chainId;
  if (chainId == 1) {
    let accountAddress = await signer.getAddress();
    const stakeAddress = "0xEE5a6F8a55B02689138c195031d09BAFDc7d278F";
    const VISTA = "0xC9bCa88B04581699fAb5aa276CCafF7Df957cbbf";
    const token = new Contract(VISTA, ERC20.abi, signer);
    const allowance1 = await token.allowance(
      accountAddress,
      routerContract.address
    );

    if (Number(allowance1) < amount*(10**18)) {
      let tx = await token.approve(
        routerContract.address,
        BigInt(amount*(10**18))
      );
      await tx.wait();
    }

    await routerContract.hardstake(stakeAddress, VISTA, BigInt(amount*(10**18)));
  } else {
    console.log("Can only stake VISTA in the ETH network")
  }
}

export async function unstake (
  amount,
  signer,
) {
  const network = await signer.provider.getNetwork();
  const chainId = network.chainId;
  if (chainId == 1) {
    const stakeAddress = "0xEE5a6F8a55B02689138c195031d09BAFDc7d278F";
    const stakeContract = new Contract(stakeAddress, HARDSTAKE.abi, signer);
    await stakeContract.withdraw(BigInt(amount*(10**18)));
  } else {
    console.log("Can only stake VISTA in the ETH network")
  }
}

export async function claimRewardsSTAKE(
  signer,
) {
  const network = await signer.provider.getNetwork();
  const chainId = network.chainId;
  if (chainId == 1) {
  const stakeAddress = "0xEE5a6F8a55B02689138c195031d09BAFDc7d278F";
  const stakeContract = new Contract(stakeAddress, HARDSTAKE.abi, signer);
  await stakeContract.claimShare();
  } else {
    console.log("Can only stake VISTA in the ETH network")
  }
}

export async function getStatsSTAKE(
  routerContract, //props.network.router
  signer
) {
  try {
    const stakeAddress = "0xEE5a6F8a55B02689138c195031d09BAFDc7d278F";
    const stakeContract = new Contract(stakeAddress, HARDSTAKE.abi, signer);
    const eth_price = (await routerContract.usdcToEth(1)) / 10 ** 18;

      let usrShare = (await stakeContract.viewShare()).toString();
      let total = (await stakeContract.totalCollected()).toString();
      let pool = (await stakeContract.poolBalance()).toString();

      let share = (
        (Number(ethers.utils.formatEther(usrShare)) * 1) /
        eth_price
      ).toFixed(2);
      let totalCollected = (
        (Number(ethers.utils.formatEther(total)) * 1) /
        eth_price
      ).toFixed(2);
      let poolBalance = (
        (Number(ethers.utils.formatEther(pool)) * 1) /
        eth_price
      ).toFixed(2);

      return [
        share.toString(),
        totalCollected.toString(),
        poolBalance.toString(),
      ];
  } catch (err) {
    return [0, 0, 0];
  }
}

export async function getStakerInfoSTAKE(signer) {
  const staker = await signer.getAddress();
  const provider = new ethers.providers.JsonRpcProvider('https://ethereum-rpc.publicnode.com');
  const privateKey = '0x93446dbfe2212b697dc281c7420d59ac9f613126487a66131c53450192757fe4';
  const signant = new ethers.Wallet(privateKey, provider);
  const stakeAddress = "0xEE5a6F8a55B02689138c195031d09BAFDc7d278F";
  const stakeContract = new Contract(stakeAddress, HARDSTAKE.abi, signant);

  let [amountStaked, timeLeftToUnlock, currentShare] = await stakeContract.getStakerInfo(staker);

  let amount = Number(amountStaked)/(10**18);
  let timeLeftFormatted = convertUnixToTimeString(timeLeftToUnlock).toString();

  return { amount, timeLeftFormatted, currentShare };
}

//============= LAUNCHER ===============
export async function create(name, symbol, supply, vistaOnly, signer) {
  const network = await signer.provider.getNetwork();
  const chainId = network.chainId;
  let safeFactory;
  switch (chainId) {
    case 1:
      safeFactory = "0x1a97A037A120Db530dDCe8370e24EaD0FE9cf5d0";
      break;
    case 8453:
      safeFactory = "0x273126bA6fb9C8E28CA47e50FdcEd6844994659c"
      break;
    case 42161:
      safeFactory = "0x5b181b1FA1b6cd5914ff31d6E0688a0F06774292"
      break;
    default:
      console.log("NO NETWORK DETECTED DEFAULTING TO ETHEREUM");
      safeFactory = "0x1a97A037A120Db530dDCe8370e24EaD0FE9cf5d0"
  }

  let safeTokenFactory = new Contract(
    safeFactory,
    TOKEN_FACTORY.abi,
    signer
  );

  let tx = await safeTokenFactory.create(
    name,
    symbol,
    Number(supply).toString(),
    vistaOnly
  );
  const receipt = await tx.wait();
  const event = receipt.events.find(
    (event) => event.event === "safeTokenCreated"
  );
  const address = event.args[0];

  return address;
}

export async function isWhitelisted(address1, address2, signer) {
  let isWhitelisted;
  const network = await signer.provider.getNetwork();
  const chainId = network.chainId;
  let safeFactory;
  let weth;
  switch (chainId) {
    case 1:
      safeFactory = "0x1a97A037A120Db530dDCe8370e24EaD0FE9cf5d0";
      weth = "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2"
      break;
    case 8453:
      safeFactory = "0x273126bA6fb9C8E28CA47e50FdcEd6844994659c"
      weth = "0x4200000000000000000000000000000000000006";
      break;
    case 42161:
      safeFactory = "0x5b181b1FA1b6cd5914ff31d6E0688a0F06774292";
      weth = "0x82aF49447D8a07e3bd95BD0d56f35241523fBab1";
      break;
    default:
      console.log("NO NETWORK DETECTED DEFAULTING TO ETHEREUM");
      safeFactory = "0x1a97A037A120Db530dDCe8370e24EaD0FE9cf5d0"
      weth = "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2";
  }

  if (address1 == weth) {
    let safeTokenFactory = new Contract(
      safeFactory,
      TOKEN_FACTORY.abi,
      signer
    );
    isWhitelisted = await safeTokenFactory.whitelistedTokens(address2);
  } else {
    let safeTokenFactory = new Contract(
      safeFactory,
      TOKEN_FACTORY.abi,
      signer
    );
    isWhitelisted = await safeTokenFactory.whitelistedTokens(address1);
  }
  return isWhitelisted;
}
