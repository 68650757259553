import React from "react";
import { Grid } from "@material-ui/core";
import PropTypes from "prop-types";
import "../assets/styles.css";
import "../assets/styles2.css";
import goldcoin from "../assets/images/goldcoin.png";
import dropdown from "../assets/images/arrow.png";


CoinField.propTypes = {
  onClick: PropTypes.func.isRequired,
  symbol: PropTypes.string.isRequired,
  logo: PropTypes.any.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  activeField: PropTypes.bool.isRequired,
  onMaxClick: PropTypes.func,
  showMaxButton: PropTypes.bool,
};

export function RemoveLiquidityField1(props) {
  const { onClick, symbol, logo, value, onChange, activeField } = props;
  return (
    <div className="container_blank">
      <div className="centered">
        <div
          size="small"
          variant="extended"
          onClick={onClick}
          className="selectBox"
          style={{
            boxShadow: "none",
            fontFamily: "Inter",
            minHeight: "45px",
            cursor: "pointer",
            backgroundColor: "#0d0d0d",
          }}
        >
          {logo ? <img src={logo} alt={logo} style={{ maxWidth: '30px', maxHeight: '30px' }} /> : null}
          {symbol ? <p alt={symbol} style={{ margin: "5px" }}>{symbol}</p>: null}
          <img
            src={dropdown}
            alt="dropdown"
            className="dropdown"
            style={{
              maxWidth: "35px",
              maxHeight: "25px",
              padding: "0 5px 0 0",
              cursor: "pointer",
            }}
          ></img>
        </div>
        <div className="BalanceBox">
          <input
            value={value}
            onChange={onChange}
            placeholder="0.0"
            disabled={!activeField}
            style={{fontSize: "24px"}}
            className="BalanceBoxBody balanceFontSize"
            onKeyPress={(event) => {
              const charCode = event.which ? event.which : event.keyCode;
              if (
                charCode !== 46 && // .
                charCode > 31 && (charCode < 48 || charCode > 57) // chiffres de 0 à 9
              ) {
                event.preventDefault();
              }
            }}
          />
        </div>
      </div>
    </div>
  );
}

export function RemoveLiquidityField2(props) {
  const { onClick, symbol, logo } = props;

  return (
    <div className="container_blank fullwidth">
      <div className="centered">
        <div
          size="small"
          variant="extended"
          onClick={onClick}
          className="selectBox"
          style={{
            // borderRadius: "0",
            boxShadow: "none",
            fontFamily: "Inter",
            minHeight: "45px",
            cursor: "pointer",
            backgroundColor: "#0d0d0d",
          }}
        >
          {logo ? <img src={logo} alt={logo} style={{ maxWidth: '30px', maxHeight: '30px' }} /> : null}
          {symbol ? <p alt={symbol} style={{ margin: "5px" }}>{symbol}</p>: null}
          <img
            src={dropdown}
            alt="dropdown"
            className="dropdown"
            style={{
              maxWidth: "35px",
              maxHeight: "25px",
              padding: "0 5px 0 0",
              cursor: "pointer",
            }}
          ></img>
        </div>
      </div>
    </div>
  );
}

export default function CoinField(props) {
  const { onClick, symbol, logo, value, onChange, activeField, onMaxClick, showMaxButton } = props;

  return (
    <div>
      <div className="selectBoxes">
        
        
        <div className="BalanceBox BalanceBox-column">
          <div className="container-maxbutton-wallet">
          {/* montrer le Max button seulement si showMaxButton est true */}
          {showMaxButton && (<button
              className="maxButton"
              onClick={onMaxClick}
              style={{
                padding: "2px 5px",
                fontFamily: "Inter",
                fontSize: "10px",
                marginTop: "15px",
                marginLeft: "10px",
                marginRight: "10px",
                cursor: "pointer",
                minWidth: "40px",
              }}>Max</button>
            )
          }
          
          </div>
          <div className="container-maxbutton-wallet">
            <input
              value={value}
              onChange={onChange}
              placeholder="0.0"
              disabled={!activeField}
              width={"158px"}             
              className="BalanceBoxBody balanceFontSize"
              onKeyPress={(event) => {
                const charCode = event.which ? event.which : event.keyCode;
                if (
                  charCode !== 46 && // .
                  charCode > 31 && (charCode < 48 || charCode > 57) // chiffres de 0 à 9
                ) {
                  event.preventDefault();
                }
              }}
            />          
            <div
            className="selectBox"
            onClick={onClick}
            style={{
              // borderRadius: "0",
              boxShadow: "none",
              fontFamily: "Inter",
              minHeight: "45px",
              cursor: "pointer",
            }}
            >
              {logo ? <img src={logo} alt={logo} style={{ maxWidth: '30px', maxHeight: '30px' }} /> : null}
              {symbol ? <p alt={symbol} style={{ margin: "5px" }}>{symbol}</p>: null}
              <img
                src={dropdown}
                alt="dropdown"
                className="dropdown"
                style={{
                  maxWidth: "35px",
                  maxHeight: "25px",
                  padding: "0 5px 0 0",
                  cursor: "pointer",
                }}
              ></img>          
            </div>    
          </div>
          
             
        </div>        
      </div>      
    </div>
  );
}
