import { useState, useEffect } from "react";

export const useWindowDrag = (ref) => {
  const [isDragging, setIsDragging] = useState(false);
  const [dragOffset, setDragOffset] = useState({ x: 0, y: 0 });

  const handleMouseDown = (e) => {
    setIsDragging(true);
    const offsetX = e.clientX - ref.current.getBoundingClientRect().left;
    const offsetY = e.clientY - ref.current.getBoundingClientRect().top;
    setDragOffset({ x: offsetX, y: offsetY });
    document.body.style.userSelect = "none";
  };

  useEffect(() => {
    const handleMouseMove = (e) => {
      if (isDragging) {
        const newX = e.clientX - dragOffset.x;
        const newY = e.clientY - dragOffset.y;
        const screenWidth = window.innerWidth;
        const screenHeight = window.innerHeight;
        const containerWidth = ref.current.offsetWidth;
        const containerHeight = ref.current.offsetHeight;

        const maxLeftPosition = 0;
        const maxRightPosition = screenWidth - containerWidth;
        const maxBottomPosition = screenHeight - 35 - containerHeight;

        const boundedX = Math.max(
          Math.min(newX, maxRightPosition),
          maxLeftPosition
        );
        const boundedY = Math.max(Math.min(newY, maxBottomPosition), 0);

        ref.current.style.left = boundedX + "px";
        ref.current.style.top = boundedY + "px";
      }
    };

    const handleMouseUp = () => {
      setIsDragging(false);
      document.body.style.userSelect = "auto";
    };

    if (isDragging) {
      document.addEventListener("mousemove", handleMouseMove);
      document.addEventListener("mouseup", handleMouseUp);
    }

    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
    };
  }, [isDragging, dragOffset]);

  return handleMouseDown;
};