import React from "react";
import lime from "../../assets/images/lime.png";
const LimeIcon = () => {
  return (
    <img
      src={lime}
      alt="Lime Logo"
      width="45"
      height="45"
      style={{ pointerEvents: "none" }}
    />
  );
};

export default LimeIcon;
