import { Contract, ethers } from "ethers";
import { fetchReserves, getDecimals } from "../ethereumFunctions";
import "../assets/styles.css";
import "../assets/styles2.css";
const ERC20 = require("../build/ERC20.json");
const PAIR = require("../build/IUniswapV2Pair.json");

 /* global BigInt */

export async function addLiquidity(
  address1,
  address2,
  amount1,
  amount2,
  amount1min,
  amount2min,
  routerContract,
  account,
  signer
) {
  account = await signer.getAddress();
  const token1 = new Contract(address1, ERC20.abi, signer);
  const token2 = new Contract(address2, ERC20.abi, signer);

  const token1Decimals = await getDecimals(token1);
  const token2Decimals = await getDecimals(token2);

  const amountIn1 = ethers.utils.parseUnits(amount1, token1Decimals);
  const amountIn2 = ethers.utils.parseUnits(amount2, token2Decimals);

  const amount1Min = ethers.utils.parseUnits(amount1min, token1Decimals);
  const amount2Min = ethers.utils.parseUnits(amount2min, token2Decimals);

  const time = Math.floor(Date.now() / 1000) + 200000;
  const deadline = ethers.BigNumber.from(time);

  const wethAddress = await routerContract.WETH();

  if (address1 === wethAddress) {
    const allowance2 = await token2.allowance(account, routerContract.address);

    if (allowance2 < amountIn2) {
      let tx = await token2.approve(routerContract.address, BigInt(amountIn2));
      await tx.wait();
    }

    await routerContract.addLiquidityETH(
      address2,
      BigInt(amountIn2),
      BigInt(amount2Min),
      BigInt(amount1Min),
      deadline,
      { value: BigInt(amountIn1) }
    );
  } else if (address2 === wethAddress) {
    const allowance1 = await token1.allowance(account, routerContract.address);

    if (allowance1 < amountIn1) {
      let tx = await token1.approve(routerContract.address, BigInt(amountIn1));
      await tx.wait();
    }

    await routerContract.addLiquidityETH(
      address1,
      BigInt(amountIn1),
      BigInt(amount1Min),
      BigInt(amount2Min),
      deadline,
      { value: BigInt(amountIn2) }
    );
  }
}

export async function removeLiquidity(
  address1,
  address2,
  liquidity_tokens,
  amount1min,
  amount2min,
  routerContract,
  account,
  signer,
  factory
) {
  account = await signer.getAddress();
  const token1 = new Contract(address1, ERC20.abi, signer);
  const token2 = new Contract(address2, ERC20.abi, signer);

  const token1Decimals = await getDecimals(token1);
  const token2Decimals = await getDecimals(token2);

  const Getliquidity = (liquidity_tokens) => {
    if (liquidity_tokens < 0.001) {
      return ethers.BigNumber.from(liquidity_tokens * 10 ** 18);
    }
    return ethers.utils.parseUnits(String(liquidity_tokens), 18);
  };

  const liquidity = Getliquidity(liquidity_tokens);
  console.log("liquidity: ", liquidity);

  const amount1Min = ethers.utils.parseUnits(
    String(amount1min),
    token1Decimals
  );
  const amount2Min = ethers.utils.parseUnits(
    String(amount2min),
    token2Decimals
  );

  const time = Math.floor(Date.now() / 1000) + 200000;
  const deadline = ethers.BigNumber.from(time);

  const wethAddress = await routerContract.WETH();
  const pairAddress = await factory.getPair(address1, address2);
  const pair = new Contract(pairAddress, PAIR.abi, signer);

  const allowance = await pair.allowance(account, routerContract.address);

  if (Number(allowance) < Number(liquidity)) {
    let tx = await pair.approve(routerContract.address, BigInt(liquidity));
    await tx.wait();
  }

  if (address1 === wethAddress) {
    await routerContract.removeLiquidityETHSupportingFeeOnTransferTokens(
      address2,
      BigInt(liquidity),
      BigInt(amount2Min),
      BigInt(amount1Min),
      deadline
    );
  } else if (address2 === wethAddress) {
    await routerContract.removeLiquidityETHSupportingFeeOnTransferTokens(
      address1,
      BigInt(liquidity),
      BigInt(amount1Min),
      BigInt(amount2Min),
      deadline
    );
  }
}
const quote = (amount1, reserve1, reserve2) => {
  const amount2 = amount1 * (reserve2 / reserve1);
  return [amount2];
};
async function quoteMintLiquidity(
  address1,
  address2,
  amountA,
  amountB,
  factory,
  signer
) {
  const MINIMUM_LIQUIDITY = 1000;
  let _reserveA = 0;
  let _reserveB = 0;
  let totalSupply = 0;
  [_reserveA, _reserveB, totalSupply] = await factory
    .getPair(address1, address2)
    .then(async (pairAddress) => {
      if (pairAddress !== "0x0000000000000000000000000000000000000000") {
        const pair = new Contract(pairAddress, PAIR.abi, signer);

        const reservesRaw = await fetchReserves(
          address1,
          address2,
          pair,
          signer
        ); // Returns the reserves already formated as ethers
        const reserveA = reservesRaw[0];
        const reserveB = reservesRaw[1];

        const _totalSupply = await pair.totalSupply();
        const totalSupply = Number(ethers.utils.formatEther(_totalSupply));
        return [reserveA, reserveB, totalSupply];
      } else {
        return [0, 0, 0];
      }
    });

  const token1 = new Contract(address1, ERC20.abi, signer);
  const token2 = new Contract(address2, ERC20.abi, signer);

  // Need to do all this decimals work to account for 0 decimal numbers

  const token1Decimals = await getDecimals(token1);
  const token2Decimals = await getDecimals(token2);

  const valueA = amountA * 10 ** token1Decimals;
  const valueB = amountB * 10 ** token2Decimals;

  const reserveA = _reserveA * 10 ** token1Decimals;
  const reserveB = _reserveB * 10 ** token2Decimals;

  if (totalSupply == 0) {
    return Math.sqrt(valueA * valueB - MINIMUM_LIQUIDITY) * 10 ** -18;
  }

  return Math.min(
    (valueA * totalSupply) / reserveA,
    (valueB * totalSupply) / reserveB
  );
}

export async function quoteAddLiquidity(
  address1,
  address2,
  amountADesired,
  amountBDesired,
  factory,
  signer
) {
  const pairAddress = await factory.getPair(address1, address2);
  const pair = new Contract(pairAddress, PAIR.abi, signer);

  const reservesRaw = await fetchReserves(address1, address2, pair, signer); // Returns the reserves already formated as ethers
  const reserveA = reservesRaw[0];
  const reserveB = reservesRaw[1];

  if (reserveA === 0 && reserveB === 0) {
    const amountOut = await quoteMintLiquidity(
      address1,
      address2,
      amountADesired,
      amountBDesired,
      factory,
      signer
    );
    return [amountADesired, amountBDesired, amountOut.toPrecision(8)];
  } else {
    const amountBOptimal = quote(amountADesired, reserveA, reserveB);
    if (amountBOptimal <= amountBDesired) {
      const amountOut = await quoteMintLiquidity(
        address1,
        address2,
        amountADesired,
        amountBOptimal,
        factory,
        signer
      );
      return [amountADesired, amountBOptimal, amountOut.toPrecision(8)];
    } else {
      const amountAOptimal = quote(amountBDesired, reserveB, reserveA);
      const amountOut = await quoteMintLiquidity(
        address1,
        address2,
        amountAOptimal,
        amountBDesired,
        factory,
        signer
      );
      return [amountAOptimal, amountBDesired, amountOut.toPrecision(8)];
    }
  }
}

export async function quoteRemoveLiquidity(
  address1,
  address2,
  liquidity,
  factory,
  signer
) {
  const pairAddress = await factory.getPair(address1, address2);
  console.log("pair address", pairAddress);
  const pair = new Contract(pairAddress, PAIR.abi, signer);

  const reservesRaw = await fetchReserves(address1, address2, pair, signer); // Returns the reserves already formated as ethers
  const reserveA = reservesRaw[0];
  const reserveB = reservesRaw[1];

  /*
  const feeOn =
    (await factory.feeTo()) !== 0x0000000000000000000000000000000000000000;
  */

  //const _kLast = await pair.kLast();
  //const kLast = Number(ethers.utils.formatEther(_kLast));

  const _totalSupply = await pair.totalSupply();
  let totalSupply = Number(ethers.utils.formatEther(_totalSupply));

  /*
  if (feeOn && kLast > 0) {
    const feeLiquidity =
      (totalSupply * (Math.sqrt(reserveA * reserveB) - Math.sqrt(kLast))) /
      (5 * Math.sqrt(reserveA * reserveB) + Math.sqrt(kLast));
    totalSupply = totalSupply + feeLiquidity;
  }
*/

  const Aout = (reserveA * liquidity) / totalSupply;
  const Bout = (reserveB * liquidity) / totalSupply;

  return [liquidity, Aout, Bout];
}

//================== LAUNCHER ===================

export async function launch(
  address1,
  address2,
  amount1,
  amount2,
  amount1min,
  amount2min,
  routerContract,
  account,
  signer,
  buyLpFee,
  sellLpFee,
  buyCreatorFee,
  sellCreatorFee,
  protocolAddress
) {
  account = await signer.getAddress();
  const token1 = new Contract(address1, ERC20.abi, signer);
  const token2 = new Contract(address2, ERC20.abi, signer);

  const token1Decimals = await getDecimals(token1);
  const token2Decimals = await getDecimals(token2);

  const amountIn1 = ethers.utils.parseUnits(amount1, token1Decimals);
  const amountIn2 = ethers.utils.parseUnits(amount2, token2Decimals);

  const amount1Min = ethers.utils.parseUnits(amount1min, token1Decimals);
  const amount2Min = ethers.utils.parseUnits(amount2min, token2Decimals);

  //const time = Math.floor(Date.now() / 1000) + 200000;
  //const deadline = ethers.BigNumber.from(time);

  const wethAddress = await routerContract.WETH();

  if (address1 === wethAddress) {
    const allowance2 = await token2.allowance(account, routerContract.address);

    if (allowance2 < amountIn2) {
      let tx = await token2.approve(routerContract.address, amountIn2);
      await tx.wait();
    }

    await routerContract.launch(
      address2,
      amountIn2,
      amount2Min,
      amount1Min,
      buyLpFee,
      sellLpFee,
      buyCreatorFee,
      sellCreatorFee,
      protocolAddress,
      { value: amountIn1 }
    );
  } else if (address2 === wethAddress) {
    const allowance1 = await token1.allowance(account, routerContract.address);

    if (allowance1 < amountIn1) {
      let tx = await token1.approve(routerContract.address, amountIn1);
      await tx.wait();
    }

    await routerContract.launch(
      address1,
      amountIn1,
      amount1Min,
      amount2Min,
      buyLpFee,
      sellLpFee,
      buyCreatorFee,
      sellCreatorFee,
      protocolAddress,
      { value: amountIn2 }
    );
  }
}

export async function setMeta(
  address1,
  address2,
  websiteUrl,
  Description,
  Image,
  Chat,
  InputSocialUrl,
  factory,
  signer
) {
  const pairAddress = await factory.getPair(address1, address2);
  const pair = new Contract(pairAddress, PAIR.abi, signer);

  await pair.setMetadata(websiteUrl, Image, Description, Chat, InputSocialUrl);
}
