import DesktopIcon from "./DesktopIcon";
import FolderIcon from "./Icons/FolderIcon";
import { calculateInitialPosition } from "../utils/calculatePosition";

const DesktopIcons = ({ toggleWindow }) => {
  return (
    <>
    {}

      <DesktopIcon
        icon={FolderIcon}
        label="Vista Apps"
        initialPosition={calculateInitialPosition(90, 80)}
        onOpen={() => toggleWindow("container11")}
      />

      {}
    </>
  );
};

export default DesktopIcons;