export const MenuItemsNavbarHeader = [
  {
    title: "Etherfun",
    url: "https://etherfun.app/",
    cName: "nav-links",
    external: true,
  },
  {
    title: "About",
    url: "https://docs.ethervista.app/",
    cName: "nav-links",
    external: true,
    // submenu: [
    //   {
    //     title: "All about Ethervista",
    //     url: "/About/All-about-Ethervista",
    //   },
    //   {
    //     title: "Whitepaper",
    //     url: "/About/Whitepaper",
    //   },
    //   {
    //     title: "Helper",
    //     url: "/About/Helper",
    //   },
    // ],
  },
  ];
  