import React, { useState, useRef, useEffect, useMemo } from "react";
import "./assets/styles.css";
import "./assets/styles2.css";
import "./assets/fonts.css";
import { Route } from "react-router-dom";
import { SnackbarProvider } from "notistack";
import { useWindowDrag } from "./hooks/useWindowDrag";
import {
  calculateInitialPosition,
  getInitialPosition,
} from "./utils/calculatePosition";
import Web3Provider from "./Components/Web3Provider";
import Web3ProviderConnectionStatus from "./Components/Web3ProviderConnectionStatus";
import ConnectionStatus from "./Components/ConnectionStatus/ConnectionStatus";
import NavBar from "./NavBar/NavBar";
import NavBarHeader from "./NavbarHeader/NavBarHeader";
import NavBarLocker from "./Locker/NavBarLocker";
import NavBarHardstake from "./Hardstake/NavBarHardstake";
import DesktopIcons from "./Components/DesktopIcons";
import FolderIcons from "./Components/FolderIcons";
import Window from "./Components/Window";
import CoinSwapper from "./CoinSwapper/CoinSwapper";
import CoinLocker from "./Locker/CoinLocker";
import RewardsLocker from "./Locker/RewardsLocker";
import Stake from "./Hardstake/Stake";
// import StakeBoost from "./Hardstake/StakeBoost";
import RewardsStake from "./Hardstake/RewardsStake";
import Liquidity from "./Liquidity/Liquidity";
import Rewards from "./Liquidity/Rewards";
import Analytics from "./Liquidity/Analytics";
// import LiveChat from "./Components/LiveChat";
// import Boost from "./Components/Boost";
// import Tools from "./Components/Tools";
import Launcher from "./Liquidity/Launcher";
import LockVista from "./Locker/lockvista";

// import images:
// import lightbg from "./assets/images/lightTheme.jpg";
import ethericon from "./assets/images/logo.png";
import lightningIcon from "./assets/images/lightning.png";
import lockerIcon from "./assets/images/open-locker.png";
// import logoEthervista from "./assets/images/logoEthervista.gif";

const App = () => {
  const [windowVisibility, setWindowVisibility] = useState({
    container1: true,
    container2: false,
    container3: false,
    container4: false,
    container5: false,
    container6: false,
    // container7: false,
    // container8: false,
    container9: false,
    container10: false,
    container11: false,
  });
  // const [showBackgroundImage, setShowBackgroundImage] = useState(true);

  const containerRefs = {
    container1: useRef(null),
    container2: useRef(null),
    container3: useRef(null),
    container4: useRef(null),
    container5: useRef(null),
    container6: useRef(null),
    // container7: useRef(null),
    // container8: useRef(null),
    container9: useRef(null),
    container10: useRef(null),
    container11: useRef(null),
  };

  useEffect(() => {
    const updateContainerSize = (key) => {
      if (containerRefs[key].current) {
        const { offsetWidth, offsetHeight } = containerRefs[key].current;
      }
    };

    Object.keys(containerRefs).forEach((key) => {
      if (windowVisibility[key]) {
        updateContainerSize(key);
      }
    });
  }, [windowVisibility]);

  const [delayedVisibility, setDelayedVisibility] = useState({
    container1: true,
    container2: false,
    container3: false,
    container4: false,
    container5: false,
    container6: false,
    // container7: false,
    // container8: false,
    container9: false,
    container10: false,
    container11: false,
  });

  const toggleWindow = (windowName) => {
    setWindowVisibility((prev) => {
      const newVisibility = {
        ...prev,
        [windowName]: !prev[windowName],
      };

      if (windowName === "container1" && newVisibility[windowName]) {
        newVisibility.container9 = false;
        newVisibility.container10 = false;
      }
      if (windowName === "container9" && newVisibility[windowName]) {
        newVisibility.container1 = false;
        newVisibility.container10 = false;
      }
      if (windowName === "container10" && newVisibility[windowName]) {
        newVisibility.container1 = false;
        newVisibility.container9 = false;
      }

      // Delay visibility handling
      if (newVisibility[windowName]) {
        setTimeout(() => {
          setDelayedVisibility((prevDelayed) => ({
            ...prevDelayed,
            [windowName]: true,
          }));
        }, 50);
      } else {
        setDelayedVisibility((prevDelayed) => ({
          ...prevDelayed,
          [windowName]: false,
        }));
      }

      return newVisibility;
    });
  };

  const handleMouseDown1 = useWindowDrag(containerRefs.container1);
  const handleMouseDown2 = useWindowDrag(containerRefs.container2);
  const handleMouseDown3 = useWindowDrag(containerRefs.container3);
  const handleMouseDown4 = useWindowDrag(containerRefs.container4);
  const handleMouseDown5 = useWindowDrag(containerRefs.container5);
  const handleMouseDown6 = useWindowDrag(containerRefs.container6);
  // const handleMouseDown7 = useWindowDrag(containerRefs.container7);
  // const handleMouseDown8 = useWindowDrag(containerRefs.container8);
  const handleMouseDown9 = useWindowDrag(containerRefs.container9);
  const handleMouseDown10 = useWindowDrag(containerRefs.container10);
  const handleMouseDown11 = useWindowDrag(containerRefs.container11);

  const getWindowStyle = (key) => {
    const screenWidth = window.innerWidth;
    const { left, top } = getInitialPosition(key);

    if (screenWidth <= 800) {
      return {
        left: "0px",
        top: "0px",
        width: "100%",
        height: "100%",
        zIndex: 10,
      };
    }

    return {
      left: `${left}px`,
      top: `${top}px`,
      zIndex: 10,
    };
  };

  const [networkState, setNetworkState] = useState(null);

  const EthervistaProviderContent = useMemo(() => {
    return (
      <Web3Provider
        render={(network) => {
          setNetworkState(network);
          return (
            <div className="maincontainer">
              <NavBar />
              <Route exact path="/">
                <CoinSwapper network={network} />
              </Route>

              <Route exact path="/liquidity">
                <Liquidity network={network} />
              </Route>

              <Route exact path="/launcher">
                <Launcher network={network} />
              </Route>

              <Route exact path="/rewards">
                <Rewards network={network} />
              </Route>

              <Route exact path="/analytics">
                <Analytics network={network} />
              </Route>

              {/* <Route exact path="/livechat">
                <LiveChat network={network} />
              </Route> */}

              {/* <Route exact path="/boost">
                <Boost network={network} />
              </Route> */}

              {/* <Route exact path="/tools">
                <Tools network={network} />
              </Route> */}

              <Route exact path="/folderIcons">
                <FolderIcons network={network} />
              </Route>
            </div>
          );
        }}
      />
    );
  }, []);

  const LockerProviderContent = useMemo(() => {
    return (
      <Web3Provider
        render={(network) => {
          setNetworkState(network);
          return (
            <div className="maincontainer">
              <NavBarLocker />
              <Route exact path="/locker">
                <CoinLocker network={network} />
              </Route>
              <Route exact path="/lockvista">
                <LockVista network={network} />
              </Route>
              <Route exact path="/rewardsLocker">
                <RewardsLocker network={network} />
              </Route>
            </div>
          );
        }}
      />
    );
  }, []);

  const StakeProviderContent = useMemo(() => {
    return (
      <Web3Provider
        render={(network) => {
          setNetworkState(network);
          return (
            <div className="maincontainer">
              <NavBarHardstake />
              <Route exact path="/stake">
                <Stake network={network} />
              </Route>
              <Route exact path="/rewardsStake">
                <RewardsStake network={network} />
              </Route>
              {/* <Route exact path="/stakeBoost">
                <StakeBoost network={network} />
              </Route> */}
            </div>
          );
        }}
      />
    );
  }, []);

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [isNotMobile, setIsNotMobile] = useState(window.innerWidth > 768);

  // Détecter le redimensionnement de la fenêtre pour savoir si on est sur mobile
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
      setIsNotMobile(window.innerWidth > 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>

      {/* Section header */}
      <header>
        <NavBarHeader />
      </header>


      {/* <Boost /> */}
      {/* <Tools network={networkState} /> */}
      <DesktopIcons toggleWindow={toggleWindow} />

      {windowVisibility.container1 && delayedVisibility.container1 && (
        <div
          className="window container-window containerspawn"
          ref={containerRefs.container1}
          style={getWindowStyle("container1")}
        >
          <div className="title-bar" onMouseDown={handleMouseDown1}>
            <div className="title-bar-text">Router</div>
            <div className="title-bar-controls">
              <button
                aria-label="Close"
                onClick={() => toggleWindow("container1")}
              ></button>
            </div>
          </div>
          <p className="unselectable EtherVistaSubTitle">
            EtherVista : The new standard for DEXs
          </p>
          <SnackbarProvider maxSnack={3}>
            {EthervistaProviderContent}
          </SnackbarProvider>
        </div>
      )}

      {windowVisibility.container9 && delayedVisibility.container9 && (
        <div
          className="window container-window containerspawn"
          ref={containerRefs.container9}
          style={getWindowStyle("container9")}
        >
          <div className="title-bar" onMouseDown={handleMouseDown9}>
            <div className="title-bar-text">Locker</div>
            <div className="title-bar-controls">
              <button
                aria-label="Close"
                onClick={() => toggleWindow("container9")}
              ></button>
            </div>
          </div>
          <p className="unselectable EtherVistaSubTitle">
            Lock your LP without losing rewards
          </p>
          <SnackbarProvider maxSnack={3}>
            {LockerProviderContent}
          </SnackbarProvider>
        </div>
      )}

      {windowVisibility.container10 && delayedVisibility.container10 && (
        <div
          className="window container-window containerspawn"
          ref={containerRefs.container10}
          style={getWindowStyle("container10")}
        >
          <div className="title-bar" onMouseDown={handleMouseDown10}>
            <div className="title-bar-text">Hardstake</div>
            <div className="title-bar-controls">
              <button
                aria-label="Close"
                onClick={() => toggleWindow("container10")}
              ></button>
            </div>
          </div>
          <p className="unselectable EtherVistaSubTitle">
            Stake your VISTA and earn
          </p>
          <SnackbarProvider maxSnack={3}>
            {StakeProviderContent}
          </SnackbarProvider>
        </div>
      )}

      {/* {showBackgroundImage && (
        <div
          className="background-image"
          style={{
            backgroundImage: "#0a0a0a",
            width: "100vw",
            height: "100vh",
            position: "fixed",
            top: 0,
            left: 0,
            zIndex: -1,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <div
            className="gif-overlay"
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              zIndex: 1,
            }}
          >
            <img
              src={logoEthervista}
              alt="Loading GIF"
              style={{
                width: "250px",
                height: "250px",
              }}
            />
          </div>
        </div>
      )} */}

      {windowVisibility.container2 && delayedVisibility.container2 && (
        <div
          className="window container-window readmore"
          ref={containerRefs.container2}
          style={getWindowStyle("container2")}
        >
          <div className="paddingBot">
            <div className="title-bar" onMouseDown={handleMouseDown2}>
              <div className="title-bar-text">Read More</div>
              <div className="title-bar-controls">
                <button
                  aria-label="Close"
                  onClick={() => toggleWindow("container2")}
                ></button>
              </div>
            </div>

            <div className="lg-paddingsecond">
              <fieldset>
                <legend style={{fontSize: "15px", textDecoration: "underline"}}>Official links:</legend>
                <div className="window-body">
                  <p>
                    Come join us on{" "}
                    <a href="https://twitter.com/ethervista" target="#">
                      Twitter
                    </a>
                  </p>
                  <p></p>
                  <p>
                    Come join us on{" "}
                    <a href="https://discord.gg/ethervista" target="#">
                      Discord
                    </a>
                  </p>
                  <p>
                    Deployed-Contracts{" "}
                    <a
                      href="https://github.com/Ethervista/Deployed-Contracts/tree/main"
                      target="#"
                    >
                      Github
                    </a>
                  </p>
                  <p>
                    All about Hardstake{" "}
                    <a
                      href="https://github.com/Ethervista/HARDSTAKE"
                      target="#"
                    >
                      Github
                    </a>
                  </p>
                  <p>
                    Protect yourself from bots{" "}
                    <a
                      href="https://docs.flashbots.net/flashbots-protect/quick-start"
                      target="#"
                    >
                      Flashbots
                    </a>
                  </p>
                  <p>
                    Join the Trenches{" "}
                    <a
                      href="https://t.me/etherfun_trenches"
                      target="#"
                    >
                      Telegram
                    </a>
                  </p>
                </div>
              </fieldset>
            </div>
          </div>
        </div>
      )}

      {windowVisibility.container3 && delayedVisibility.container3 && (
        <div
          className="window startmenubox"
          ref={containerRefs.container3}
          style={{
            left: 0,
            top: isMobile ? "calc(100% - 322px)" : "calc(100% - 208px)",
            zIndex: 0,
          }}
        >
          <ul role="menu" className="startmenu">
            <li className="startmenulist" role="menuitem">
              <a
                href="#"
                className="startbtn startmenulista"
                id="start"
                onClick={() => toggleWindow("container4")}
              >
                <img
                  src="https://win98icons.alexmeub.com/icons/png/media_player_stream_sun4.png"
                  alt="Flashloans"
                  height={"24px"}
                  width={"24px"}
                />
                <span>Flashloans</span>
              </a>
            </li>

            <li className="startmenulist" role="menuitem">
              <a
                href="#"
                className="startbtn startmenulista"
                id="start"
                onClick={() => toggleWindow("container6")}
              >
                <img
                  src="https://win98icons.alexmeub.com/icons/png/mouse_padlock.png"
                  alt="Flashloans"
                  height={"24px"}
                  width={"24px"}
                />
                <span>Futures</span>
              </a>
            </li>

            <li className="startmenulist" role="menuitem">
              <a
                href="#"
                className="startbtn startmenulista"
                id="start"
                onClick={() => toggleWindow("container5")}
              >
                <img
                  src="https://win98icons.alexmeub.com/icons/png/regedit-0.png"
                  alt="Flashloans"
                  height={"24px"}
                  width={"24px"}
                />
                <span>Lending</span>
              </a>
            </li>

            <li className="startmenulist" role="menuitem">
              <a
                href="#"
                className="startbtn startmenulista"
                id="start"
                onClick={() => toggleWindow("container2")}
              >
                <img
                  src="https://win98icons.alexmeub.com/icons/png/help_book_cool_small-4.png"
                  alt="Read more"
                  height={"24px"}
                  width={"24px"}
                />
                <span>Read more</span>
              </a>
            </li>
            {/* Ajouter les boutons Router, Hardlock, et Hardstake si mobile */}
            {isMobile && (
              <>
                <li className="startmenulist" role="menuitem">
                  <a
                    href="#"
                    className="startbtn startmenulista"
                    onClick={() => toggleWindow("container1")}
                  >
                    <img
                  src="https://win98icons.alexmeub.com/icons/png/laptop_infrared_2-5.png"
                  alt="Read more"
                  height={"24px"}
                  width={"24px"}
                />
                    <span>Router</span>
                  </a>
                </li>
                <li className="startmenulist" role="menuitem">
                  <a
                    href="#"
                    className="startbtn startmenulista"
                    onClick={() => toggleWindow("container9")}
                  >
                    <img
                  src={lockerIcon}
                  alt="Read more"
                  height={"24px"}
                  width={"24px"}
                />
                    <span>Hardlock</span>
                  </a>
                </li>
                <li className="startmenulist" role="menuitem">
                  <a
                    href="#"
                    className="startbtn startmenulista"
                    onClick={() => toggleWindow("container10")}
                  >
                    <img
                  src={lightningIcon}
                  alt="Read more"
                  height={"24px"}
                  width={"24px"}
                />
                    <span>Hardstake</span>
                  </a>
                </li>
              </>
              )}
          </ul>
        </div>
      )}

      {windowVisibility.container4 && delayedVisibility.container4 && (
        <div
          className="window container-window loans"
          ref={containerRefs.container4}
          style={getWindowStyle("container4")}
        >
          <div className="paddingBot">
            <div className="title-bar" onMouseDown={handleMouseDown4}>
              <div className="title-bar-text">Feeless Flashloans</div>
              <div className="title-bar-controls">
                <button
                  aria-label="Close"
                  onClick={() => toggleWindow("container4")}
                ></button>
              </div>
            </div>

            <div className="lg-paddingsecond">
              <fieldset>
                <legend>Feeless Flashloans</legend>
                <div className="window-body">
                  <p>Coming soon ! </p>
                </div>
              </fieldset>
            </div>
          </div>
        </div>
      )}

      {windowVisibility.container5 && delayedVisibility.container5 && (
        <div
          className="window container-window lending"
          ref={containerRefs.container5}
          style={getWindowStyle("container5")}
        >
          <div className="paddingBot">
            <div className="title-bar" onMouseDown={handleMouseDown5}>
              <div className="title-bar-text">Lending</div>
              <div className="title-bar-controls">
                <button
                  aria-label="Close"
                  onClick={() => toggleWindow("container5")}
                ></button>
              </div>
            </div>

            <div className="lg-paddingsecond">
              <fieldset>
                <legend>Lending</legend>
                <div className="window-body">
                  <p>Coming soon ! </p>
                </div>
              </fieldset>
            </div>
          </div>
        </div>
      )}

      {windowVisibility.container6 && delayedVisibility.container6 && (
        <div
          className="window container-window futures"
          ref={containerRefs.container6}
          style={getWindowStyle("container6")}
        >
          <div className="paddingBot">
            <div className="title-bar" onMouseDown={handleMouseDown6}>
              <div className="title-bar-text">Futures</div>
              <div className="title-bar-controls">
                <button
                  aria-label="Close"
                  onClick={() => toggleWindow("container6")}
                ></button>
              </div>
            </div>

            <div className="lg-paddingsecond">
              <fieldset>
                <legend>Futures</legend>
                <div className="window-body">
                  <p>Coming soon ! </p>
                </div>
              </fieldset>
            </div>
          </div>
        </div>
      )}

      {windowVisibility.container11 && delayedVisibility.container11 && (
        <div
          className="futures window-vista-icons"
          ref={containerRefs.container11}
          style={getWindowStyle("container11")}
        >
          <div className="title-bar" onMouseDown={handleMouseDown11}>
            <span>Applications</span>
            <div className="title-bar-controls">
              <button
                aria-label="Close"
                onClick={() => toggleWindow("container11")}
              ></button>
            </div>
          </div>
          <div className="content">
            <div className="user-info">
              <FolderIcons />
            </div>
          </div>
        </div>
      )}

        <div className="taskbar outward-bevel unselectable" id="Taskbar">
          <div className="taskbar-left">
              <button
                className="startbtn"
                onClick={() => toggleWindow("container3")}
              >
                <img
                  src={ethericon}
                  height={"18px"}
                  width={"18px"}
                  alt="Windows Logo"
                />
                <span>Start</span>
              </button>
              {/* Ajouter les boutons Router, Hardlock, et Hardstake si mobile */}
              {isNotMobile && (
              <>
              <button
                className="startbtn"
                onClick={() => toggleWindow("container1")}
              >
                <span>Router</span>
              </button>
              <button
                className="startbtn"
                onClick={() => toggleWindow("container9")}
              >
                <span>Hardlock</span>
              </button>
              <button
                className="startbtn "
                onClick={() => toggleWindow("container10")}
              >
                <span>Hardstake</span>
              </button>
              </>
              )}
            </div>
        <Web3ProviderConnectionStatus
          render={(network) => (
            <div className="taskbar-right">
              <ConnectionStatus network={network} />
            </div>
          )}
        />
      </div>
    </>
  );
};

export default App;
