import React, { useState } from "react";
import "../assets/styles.css";
import "../assets/styles2.css";
import { lock } from "../ethereumFunctions";

function CoinLocker(props) {
  const [pairAddress, setPairAddress] = useState("");
  const [lockedAmount, setLockedAmount] = useState("");

  const handleLock = async () => {
    await lock(
      pairAddress,
      lockedAmount,
      props.network.router,
      props.network.signer
    );
  };

  return (
    <div className="fitcontent paddingBot">
      <div className="window scrollcontainer lg-padding" role="tabpanel">
        <fieldset className="contentBox">
          <legend
            style={{ fontSize: "12px", color: "#808080", marginBottom: "15px" }}
          >
            For creators who wish to lock their liquidity
          </legend>

          <p className="subtitles correctFont">Pair Address:</p>
          <div className="pairAddressInput">
            <input
              placeholder="Enter pair address"
              className="pairAdresseBoxbody"
              value={pairAddress}
              onChange={(e) => setPairAddress(e.target.value)}
            />
          </div>

          <p className="subtitles correctFont">Lock Amount:</p>
          <div className="BalanceBox" style={{ margin: "auto" }}>
            <input
              placeholder="Enter LP tokens amount"
              className="BalanceBoxBody"
              value={lockedAmount}
              onChange={(e) => setLockedAmount(e.target.value)}
            />
          </div>
          <p className="doNotForgetToClaim">
            *Do not forget to claim rewards before staking again
          </p>
        </fieldset>
        <button onClick={handleLock} className="metadatabuttoncontainer">
          <h6 className="metadatabutton">Lock</h6>
        </button>
      </div>
    </div>
  );
}

export default CoinLocker;
