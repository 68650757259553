import React, { useState } from "react";
import { Link } from "react-router-dom";
import { MenuItemsNavbarHeader } from "./MenuItemsNavbarHeader";

import Vistagif from "../assets/images/logoEthervista.gif";

const NavItem = ({ item, isSelected, onClick }) => (
    // <li
    // >
    //   {item.submenu ? (
    //     <div className="dropdown">
    //       <Link className={item.cName} to={item.url} onClick={onClick}>
    //         {item.title}
    //       </Link>
    //       <ul className="dropdown-content">
    //         {item.submenu.map((subItem, subIndex) => (
    //           <li key={subIndex}>
    //             <Link to={subItem.url} className="nav-links">
    //               {subItem.title}
    //             </Link>
    //           </li>
    //         ))}
    //       </ul>
    //     </div>
    //   ) : (
    //     <Link className={item.cName} to={item.url} onClick={onClick}>
    //       {item.title}
    //     </Link>
    //   )}
    // </li>
    <li>
      {item.external ? (
        <a 
          href={item.url} 
          className={item.cName} 
          target="_blank"
          rel="noopener noreferrer"  // pour meilleur sécurité
          onClick={onClick}
        >
          {item.title}
        </a>
      ) : (
        <Link className={item.cName} to={item.url} onClick={onClick}>
          {item.title}
        </Link>
      )}
    </li>
  );

const NavBarHeader = () => {
    const [selectedTab, setSelectedTab] = useState(0);
  
    return (
      <div className="NavbarHeaderContent">
        <img src={Vistagif} alt="logoEthervista" className="logoEthervista" />
        
        <menu className="mainmenu" role="tablist">
        <p className="labelEthervista">Ethervista</p>
          {MenuItemsNavbarHeader.map((item, index) => (
            <NavItem
              key={index}
              item={item}
              isSelected={selectedTab === index}
              onClick={() => setSelectedTab(index)}
            />
          ))}
        </menu>
      </div>
    );
  };

export default NavBarHeader;