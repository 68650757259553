import React, { useState, useEffect } from "react";
import "../assets/styles.css";
import "../assets/styles2.css";
import { stake, unstake, getStakerInfoSTAKE } from "../ethereumFunctions"

function Stake(props) {
  const [stakedAmount, setStakedAmount] = useState("");
  const [amount, setAmount] = useState("");
  const [lockDuration, setLockDuration] = useState("14 days");
  const [timeLeft, setTimeLeft] = useState("");

  useEffect(() => {
    const fetchStakerInfo = async () => {
      try {
        const { amount, timeLeftFormatted, currentShare } = await getStakerInfoSTAKE(props.network.signer);
        setStakedAmount(amount.toString());
        setTimeLeft(timeLeftFormatted.toString());
      } catch (error) {
        console.error("Error fetching staker info:", error);
      }
    };

    if (props.network.signer) {
      fetchStakerInfo();
    }
  }, [props.network.signer]);

  const handleStake = async () => {
    await stake(amount, props.network.router, props.network.signer);
  };

  const handleUnstake = async () => {
    await unstake(amount, props.network.signer);
  };

  return (
    <div className="fitcontent paddingBot">
      <div className="window scrollcontainer lg-padding" role="tabpanel">
        <fieldset className="contentBox">
          <legend style={{fontSize:"12px", color: "#808080", marginBottom: "15px"}}>Stake</legend>
            <p className="subtitles correctFont">Staked amount:
            <span className="stakeInfo">
                {stakedAmount}
            </span>
            </p>

          <p className="subtitles correctFont">
            Lock time: 
            <span className="stakeInfo">
              {lockDuration}
            </span>
          </p>
          
          <p className="subtitles correctFont">
            Time left: 
            <span className="stakeInfo">
              {timeLeft}
            </span>
          </p>

          <p className="subtitles correctFont">Amount:</p>
          <div className="BalanceBox">
            <input
              placeholder="Enter amount"
              className="BalanceBoxBody"
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
            />
          </div>
          <p className="doNotForgetToClaim">*Do not forget to claim rewards before
          staking/unstaking else they will be lost.</p>
          <p style={{fontSize: "10px"}}>* Staking or unstaking will reset your timer</p>
        </fieldset>
        <button onClick={handleStake} className="metadatabuttoncontainer">
        <h6 className="metadatabutton">Stake</h6>
      </button>
      <button onClick={handleUnstake} className="metadatabuttoncontainer">
        <h6 className="metadatabutton">Unstake</h6>
      </button>
      </div>      
    </div>
  );
}

export default Stake;