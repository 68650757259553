import React from 'react';
import styles from './ConnectionStatus.module.css';
import EthereumLogo from "../../assets/images/ethereumlogo.png"
import ArbitrumLogo from "../../assets/images/arbitrumlogo.png"
import BaseLogo from "../../assets/images/baselogo.png"

const ConnectionStatus = ({ network }) => {
  const chainId = network?.chainID;
    const isConnected = chainId !== undefined && chainId !== null;
    let logo, altText;
    
    if (isConnected) {
        switch(chainId) {
          case 1:
            logo = EthereumLogo;
            altText = "Ethereum";
            break;
          case 42161:
            logo = ArbitrumLogo;
            altText = "Arbitrum";
            break;
          case 8453:
            logo = BaseLogo;
            altText = "Base";
            break;
          default:
            logo = null;
            altText = "Unknown";
        }
      }
  
      return (
        <div className={`${styles.container} ${isConnected ? styles.connected : styles.disconnected}`}>
          {isConnected && logo && (
            <img
              src={logo}
              alt={altText}
              className={styles.image}
            />
          )}
          <span className={styles.text}>{isConnected ? 'Connected' : 'Disconnected'}</span>
        </div>
      );
};

  export default ConnectionStatus;