export const calculateInitialPosition = (xPercent, yPixels) => {
    return {
      x: (window.innerWidth * xPercent) / 100,
      y: yPixels
    };
  };
  
export const getInitialPosition = (key) => {
  const screenWidth = window.innerWidth;
  const screenHeight = window.innerHeight;
  const containerWidth = 600;
  const containerHeight = 400;

  if (screenWidth <= 800) {
    return { left: 0, top: 0 };
  }

  const centerX = (screenWidth - containerWidth) / 2;
  const centerY = (screenHeight - containerHeight) / 2;

  switch (key) {
    case "container1":
      return {
        left: centerX,
        top: 25,
      };
    case "container2":
      return {
        left: centerX + 50,
        top: centerY + 50,
      };
    case "container3":
      return {
        left: 0,
        top: 500,
      };
    case "container4":
      return {
        left: centerX - 100,
        top: centerY - 50,
      };
    case "container5":
      return {
        left: centerX + 100,
        top: centerY - 50,
      };
    case "container6":
      return {
        left: centerX,
        top: centerY + 100,
      };
    case "container7":
      return {
        left: screenWidth - 200 - containerWidth,
        top: 50,
      };
    case "container8":
      return {
        left: screenWidth - 300 - containerWidth,
        top: 100,
      };
    case "container9":
      return {
        left: centerX,
        top: 25,
      };
    case "container10":
      return {
        left: centerX,
        top: 25,
      };
      case "container11":
        return {
          left: centerX,
          top: 25,
        };
    default:
      return { left: 0, top: 0 };
  }
};