import React from "react";
import rover from "../../assets/images/roverlogo.png";
const RoverIcon = () => {
  return (
    <img
      src={rover}
      alt="Rover Logo"
      width="60"
      height="60"
      style={{ pointerEvents: "none" }}
    />
  );
};

export default RoverIcon;