import React from "react";
import Minesweeperlogo from '../../assets/images/minesweeper.png';

const MinesweeperIcon = () => {
  return (
    <img 
      src={Minesweeperlogo} 
      alt="Ninja Logo" 
      width="45" 
      height="45" 
      style={{ pointerEvents: 'none' }}
    />
  );
};

export default MinesweeperIcon;