import { useWeb3Connection } from "../hooks/useWeb3Connection";
import ConnectionStatus from "./ConnectionStatus/ConnectionStatus";

const Web3ProviderConnectionStatus = (props) => {
  const { isConnected, network } = useWeb3Connection();

  const renderNotConnected = () => (
    <div className="App">
      <div>
        <ConnectionStatus />
      </div>
    </div>
  );

  return (
    <>
      {!isConnected && renderNotConnected()}
      {isConnected && <div>{props.render(network)}</div>}
    </>
  );
};

export default Web3ProviderConnectionStatus;