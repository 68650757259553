export const networks = [1, 42161, 8453];

export const ChainId = {
  MAINNET: 1,
  BASE: 8453,
  ARBITRUM: 42161,

};

export const routerAddress = new Map();
routerAddress.set(
  ChainId.MAINNET,
  "0xCEDd366065A146a039B92Db35756ecD7688FCC77"
);
routerAddress.set(ChainId.BASE, "0x4Aab7db193bf3d0083e9db51735Ab3da0328C3d7");
routerAddress.set(
  ChainId.ARBITRUM,
  "0x1fD8A96D964b3169c083e292134378E17452C731"
);

