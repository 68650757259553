import React, { useState, useEffect } from "react";
import { getBalanceAndSymbol, create } from "../ethereumFunctions";
import { launch, setMeta } from "./LiquidityFunctions";
import CoinField from "../CoinSwapper/CoinField";
import CoinDialog from "../CoinSwapper/CoinDialog";
import "../assets/styles.css";
import "../assets/styles2.css";
import question from "../assets/images/interrogation.png";

function LiquidityDeployer(props) {
  const [isInitialized, setIsInitialized] = useState(false);


  const [field1Value, setField1Value] = useState(""); // CoinField 1
  const [field2Value, setField2Value] = useState(""); // CoinField 2

  const [inputBuyLiqValue, setInputBuyLiqValue] = useState(""); // Buy Liq
  const [inputSellCreatorValue, setInputSellCreatorValue] = useState(""); // SellCreator
  const [inputBuyCreatorFeeValue, setInputBuyCreatorFeeValue] = useState(""); // BuyCreator Fee
  const [inputSellCreatorFeeValue, setInputSellCreatorFeeValue] = useState(""); // SellCreator Fee
  const [inputProtocolAddressValue, setInputProtocolAddressValue] =
    useState(""); // Protocol Address

  const [inputWebsiteURL, setInputWebsiteURL] = useState(""); // Website URL
  const [inputTokenDescription, setInputTokenDescription] = useState(""); // Description
  const [inputImageURL, setInputImageURL] = useState(""); // Image URL
  const [inputChatURL, setInputChatURL] = useState(""); // Chat URL
  const [inputSocialURL, setInputSocialURL] = useState(""); // Social URL
  const [dialog1Open, setDialog1Open] = useState(false);
  const [dialog2Open, setDialog2Open] = useState(false);

  const [name, setName] = useState("");
  const [symbol, setSymbol] = useState("");
  const [supply, setSupply] = useState("");
  const [vistaOnly, setVistaOnly] = useState("");

  const [tooltipVisible, setTooltipVisible] = React.useState(false);

  const [coin1, setCoin1] = useState({
    address: undefined,
    symbol: undefined,
    balance: undefined,
  });
  const [coin2, setCoin2] = useState({
    address: undefined,
    symbol: undefined,
    balance: undefined,
  });

  useEffect(() => {
    if (!isInitialized && props.network && props.network.weth && props.network.weth.address) {
      getBalanceAndSymbol(
        props.network.account,
        props.network.weth.address,
        props.network.provider,
        props.network.signer,
        props.network.weth.address,
        props.network.coins
      ).then((data) => {
        setCoin1({
          address: props.network.weth.address,
          symbol: data.symbol,
          balance: data.balance,
        });
        setIsInitialized(true);
      });
    }
  }, [props.network.weth, props.network.account, isInitialized]);

  // Update balances periodically
  useEffect(() => {
    const updateBalances = () => {
      if (coin1.address && props.network.account) {
        getBalanceAndSymbol(
          props.network.account,
          coin1.address,
          props.network.provider,
          props.network.signer,
          props.network.weth.address,
          props.network.coins
        ).then((data) => {
          setCoin1(prevCoin => ({
            ...prevCoin,
            balance: data.balance
          }));
        });
      }
      if (coin2.address && props.network.account) {
        getBalanceAndSymbol(
          props.network.account,
          coin2.address,
          props.network.provider,
          props.network.signer,
          props.network.weth.address,
          props.network.coins
        ).then((data) => {
          setCoin2(prevCoin => ({
            ...prevCoin,
            balance: data.balance
          }));
        });
      }
    };

    const intervalId = setInterval(updateBalances, 10000);
    return () => clearInterval(intervalId);
  }, [coin1.address, coin2.address, props.network.account]);

  const [launchError, setLaunchError] = useState(false);

  const [displayAddress, setDisplayAddress] = useState("");

  // Dummy function pour ladresse
  const updateAddress = async () => {
    await create(name, symbol, supply, vistaOnly, props.network.signer).then(
      (value) => setDisplayAddress(value)
    );
  };

  const handleCoinFieldChange = {
    field1: (e) => setField1Value(e.target.value),
    field2: (e) => setField2Value(e.target.value),
  };

  const handleInputChange = {
    buyLiq: (e) => {
      const value = e.target.value;
      if (/^(0|[1-9]\d*)?$/.test(value) && value !== "0") {
        setInputBuyLiqValue(value);
      }
    },
    sellCreator: (e) => {
      const value = e.target.value;
      if (/^(0|[1-9]\d*)?$/.test(value) && value !== "0") {
        setInputSellCreatorValue(value);
      }
    },
    buyCreatorFee: (e) => {
      const value = e.target.value;
      if (/^(0|[1-9]\d*)?$/.test(value) && value !== "0") {
        setInputBuyCreatorFeeValue(value);
      }
    },
    sellCreatorFee: (e) => {
      const value = e.target.value;
      if (/^(0|[1-9]\d*)?$/.test(value) && value !== "0") {
        setInputSellCreatorFeeValue(value);
      }
    },
    protocolAddress: (e) => setInputProtocolAddressValue(e.target.value),
    websiteURL: (e) => setInputWebsiteURL(e.target.value),
    description: (e) => setInputTokenDescription(e.target.value),
    imageURL: (e) => setInputImageURL(e.target.value),
    chatURL: (e) => setInputChatURL(e.target.value),
    InputSocialUrl: (e) => setInputSocialURL(e.target.value),
  };

  const onToken1Selected = (address) => {
    setDialog1Open(false);
    if (address === coin2.address) {
      switchFields();
    } else if (address) {
      getBalanceAndSymbol(
        props.network.account,
        address,
        props.network.provider,
        props.network.signer,
        props.network.weth.address,
        props.network.coins
      ).then((data) => {
        setCoin1({
          address: address,
          symbol: data.symbol,
          balance: data.balance,
        });
      });
    }
  };

  const onToken2Selected = (address) => {
    setDialog2Open(false);
    if (address === coin1.address) {
      switchFields();
    } else if (address) {
      getBalanceAndSymbol(
        props.network.account,
        address,
        props.network.provider,
        props.network.signer,
        props.network.weth.address,
        props.network.coins
      ).then((data) => {
        setCoin2({
          address: address,
          symbol: data.symbol,
          balance: data.balance,
        });
      });
    }
  };

  const switchFields = () => {
    // Only switch if coin2 is defined to prevent losing the WETH default
    if (coin2.address) {
      setCoin1(coin2);
      setCoin2(coin1);
      setField1Value(field2Value);
      setField2Value(field1Value);
    }
  };

  const deploy = async () => {
    if (
      !inputBuyLiqValue ||
      !inputSellCreatorValue ||
      !inputBuyCreatorFeeValue ||
      !inputSellCreatorFeeValue ||
      !inputProtocolAddressValue ||
      !coin1.address ||
      !coin2.address
    ) {
      console.error(
        "Error: All input fields must be filled and tokens must be selected."
      );
      setLaunchError(true);
      return;
    }

    try {
      const result = await launch(
        coin1.address,
        coin2.address,
        field1Value,
        field2Value,
        (field1Value*0.95).toFixed(6).toString(),
        (field2Value*0.95).toFixed(6).toString(),
        props.network.router,
        props.network.account,
        props.network.signer,
        inputBuyLiqValue,
        inputSellCreatorValue,
        inputBuyCreatorFeeValue,
        inputSellCreatorFeeValue,
        inputProtocolAddressValue,
        props.network.signer
      );
      console.log("Launch result:", result);
    } catch (error) {
      console.error("Launch error:", error);
      setLaunchError(true);
    }
  };

  const handleDummyButtonClick = async () => {
    if (
      !inputWebsiteURL ||
      !inputTokenDescription ||
      !inputImageURL ||
      !inputChatURL ||
      !inputSocialURL ||
      !coin1.address ||
      !coin2.address
    ) {
      console.error(
        "Error: All input fields must be filled and tokens must be selected."
      );
      setLaunchError(true);
      return;
    }

    try {
      const result = await setMeta(
        coin1.address,
        coin2.address,
        inputWebsiteURL,
        inputTokenDescription,
        inputImageURL,
        inputChatURL,
        inputSocialURL,
        props.network.factory,
        props.network.signer
      );
      console.log("result:", result);
    } catch (error) {
      console.error("Launch error:", error);
      setLaunchError(true);
    }
  };

  return (
    <div className="paddingBot">
      <div className="window scrollcontainer lg-padding overflowscroll">
        {/* Dialog Windows */}
        <CoinDialog
          open={dialog1Open}
          onClose={onToken1Selected}
          coins={props.network.coins}
          signer={props.network.signer}
        />
        <CoinDialog
          open={dialog2Open}
          onClose={onToken2Selected}
          coins={props.network.coins}
          signer={props.network.signer}
        />
        <fieldset className="contentBox">
          <legend>Create Safe Token (Optional)</legend>
          <section className="valueselection">
            <span>
              <p className="subtitles correctFont NoMargin NoPadding"></p>
              <div className="BalanceBox">
                <input
                  placeholder="Name"
                  className="BalanceBoxBody"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
              <p className="subtitles correctFont NoMargin NoPadding"></p>
              <div className="BalanceBox">
                <input
                  placeholder="Symbol"
                  className="BalanceBoxBody"
                  value={symbol}
                  onChange={(e) => setSymbol(e.target.value)}
                />
              </div>
            </span>
            <span>
              <p className="subtitles correctFont NoMargin NoPadding"></p>
              <div className="BalanceBox">
                <input
                  placeholder="Supply"
                  className="BalanceBoxBody"
                  value={supply}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (/^\d*$/.test(value)) {
                      setSupply(value);
                    }
                  }}
                />
              </div>
              {/* <img src={rover} alt="launch" className="rover" /> */}
            </span>
          </section>
          <p className="subtitles onlyether">
            Trade only on Ethervista and benefit from all the fees ?
          </p>
          <div className="launchsafetoken">
            <label >
              <input
                type="checkbox"
                checked={vistaOnly === "yes"}
                onChange={(e) => {
                  setVistaOnly(e.target.checked ? "yes" : "no");
                }}
                className={vistaOnly === "yes" ? "pressed" : "outward"}
              />
              <span
                className="boolean"
                style={{
                  color: vistaOnly === "yes" ? "white" : "lightgray",
                  backgroundColor: vistaOnly === "yes" ? "black" : "transparent",
                  border: vistaOnly === "yes" ? "1px solid lightgray" : "1px solid transparent",
                  marginRight: "20px",
                }}
              >
                Yes
              </span>
            </label>
            <label>
              <input
                type="checkbox"
                checked={vistaOnly === "no"}
                onChange={(e) => {
                  setVistaOnly(e.target.checked ? "no" : "yes");
                }}
                className={vistaOnly === "no" ? "pressed" : "outward"}
              />
              <span
                className="boolean"
                style={{
                  color: vistaOnly === "no" ? "white" : "lightgray",
                  backgroundColor: vistaOnly === "no" ? "black" : "transparent",
                  border: vistaOnly === "no" ? "1px solid lightgray" : "1px solid transparent",
                }}
              >
                No, I want to stay poor
              </span>
            </label>
          </div>
          <button
            onClick={async () => {
              await updateAddress();
            }}
            className="metadatabuttoncontainer"
          >
            <h6 className="metadatabutton">Create</h6>
          </button>
          <div>
            <p className="lightweight" style={{marginLeft: "5px"}}>
              Address will appear here: {displayAddress}
            </p>
          </div>
        </fieldset>
        <fieldset>
          <legend>Launch (Required)</legend>

          <div item class="window-body graybg">
            <CoinField
              activeField={true}
              value={field1Value}
              onClick={() => setDialog1Open(true)}
              onChange={handleCoinFieldChange.field1}
              symbol={coin1.symbol !== undefined ? coin1.symbol : "Select"}
            />
          </div>
          <div item class="window-body graybg">
            <CoinField
              activeField={true}
              value={field2Value}
              onClick={() => setDialog2Open(true)}
              onChange={handleCoinFieldChange.field2}
              symbol={coin2.symbol !== undefined ? coin2.symbol : "Select"}
            />
          </div>
          <fieldset className="contentBox">
          <legend className="legend2">Fees (USD value)</legend>

          <section className="valueselection">
            <span>
              <p className="subtitles correctFont NoMargin NoPadding">
                LP-Fees
              </p>
              <div className="BalanceBox bgColor-input">
                <input
                  placeholder="Buy"
                  className="BalanceBoxBody"
                  value={inputBuyLiqValue}
                  onChange={handleInputChange.buyLiq}
                />
              </div>
              <div className="BalanceBox bgColor-input">
                <input
                  placeholder="Sell"
                  className="BalanceBoxBody"
                  value={inputSellCreatorValue}
                  onChange={handleInputChange.sellCreator}
                />
              </div>
            </span>

            <span className="protocoladdress primary">
              <p className="subtitles correctFont NoPadding primary NoMargin">
                Protocol Address{" "}
                <img
                  src={question}
                  onMouseEnter={() => setTooltipVisible(true)}
                  onMouseLeave={() => setTooltipVisible(false)}
                  alt="Gold Coin"
                  className="help_button"
                ></img>
                {tooltipVisible && (
                  <p
                    style={{
                      display: "block",
                      position: "absolute",
                      fontSize: "10px",
                      width: "200px",
                      top: 350,
                      left: 110,
                      opacity: 1,
                      backgroundColor: "#000000",
                      color: "55555",
                      textAlign: "center",
                      zIndex: 1000,
                      lineHeight: 2,
                      border: "1px solid transparent",
                      borderRadius: "10px",
                      padding: "10px",
                    }}
                  >
                    Protocol address is either a<br /> smart contract which
                    handles
                    <br />
                    the fee or the creator's address
                  </p>
                )}
              </p>
              <div className="BalanceBox bgColor-input">
                <input
                  placeholder="Address"
                  className="BalanceBoxBody"
                  value={inputProtocolAddressValue}
                  onChange={handleInputChange.protocolAddress}
                />
              </div>
            </span>
          </section>
          <section className="valueselection">
            <p className="subtitles correctFont NoMargin NoPadding secondary">
              Protocol Address
            </p>

            <div className="BalanceBox secondary">
              <input
                placeholder="Address"
                className="BalanceBoxBody"
                value={inputProtocolAddressValue}
                onChange={handleInputChange.protocolAddress}
              />
            </div>
            <span>
              <p className="subtitles correctFont NoMargin NoPadding">
                Protocol fees
              </p>
              <div className="BalanceBox bgColor-input">
                <input
                  placeholder="Buy"
                  className="BalanceBoxBody"
                  value={inputBuyCreatorFeeValue}
                  onChange={handleInputChange.buyCreatorFee}
                />
              </div>

              <div className="BalanceBox bgColor-input">
                <input
                  placeholder="Sell"
                  className="BalanceBoxBody"
                  value={inputSellCreatorFeeValue}
                  onChange={handleInputChange.sellCreatorFee}
                />
              </div>
            </span>
            {/* <img src={monke} alt="launch" className="monke" /> */}
          </section>
          <button onClick={deploy} className="metadatabuttoncontainer">
            <h6 className={`metadatabutton ${launchError ? "error-text" : ""}`}>
              Launch
            </h6>
            {/* <img
              src={moon}
              alt="launch"
              className="goldCoin NoMargin NoPadding"
            /> */}
          </button>
          </fieldset>
        </fieldset>

        <fieldset className="contentBox">
          <legend>Update Metadata (Recommended)</legend>
          <section className="valueselection">
            <span>
              <p className="subtitles correctFont NoMargin NoPadding">

              </p>
              <div className="BalanceBox">
                <input
                  placeholder="Website URL"
                  className="BalanceBoxBody"
                  value={inputWebsiteURL}
                  onChange={handleInputChange.websiteURL}
                />
              </div>
              <p className="subtitles correctFont NoMargin NoPadding">
                
              </p>
              <div className="BalanceBox">
                <input
                  placeholder="Description"
                  className="BalanceBoxBody"
                  value={inputTokenDescription}
                  onChange={handleInputChange.description}
                />
              </div>
              {/* <img src={clippy} alt="launch" className="clippy" /> */}
            </span>
            <span>
              <p className="subtitles correctFont NoMargin NoPadding"></p>
              <div className="BalanceBox">
                <input
                  placeholder="Image URL"
                  className="BalanceBoxBody"
                  value={inputImageURL}
                  onChange={handleInputChange.imageURL}
                />
              </div>
              <p className="subtitles correctFont NoMargin NoPadding">

              </p>
              <div className="BalanceBox">
                <input
                  placeholder="Chat URL"
                  className="BalanceBoxBody"
                  value={inputChatURL}
                  onChange={handleInputChange.chatURL}
                />
              </div>
              <p className="subtitles correctFont NoMargin NoPadding">

              </p>
              <div className="BalanceBox">
                <input
                  placeholder="Social URL"
                  className="BalanceBoxBody"
                  value={inputSocialURL}
                  onChange={handleInputChange.InputSocialUrl}
                />
              </div>
            </span>
          </section>

          <button
            onClick={handleDummyButtonClick}
            className="metadatabuttoncontainer"
          >
            <h6 className="metadatabutton">Set Metadata</h6>
          </button>
        </fieldset>
      </div>
    </div>
  );
}

export default LiquidityDeployer;
