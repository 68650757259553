import React, { useState, useEffect } from "react";
import "../assets/styles.css";
import "../assets/styles2.css";
import {
  getBalanceAndSymbol,
  getStatsLOCK,
  claimRewardsLOCK,
} from "../ethereumFunctions";
import CoinDialog from "../CoinSwapper/CoinDialog";

function RewardsLocker(props) {
  const [userRewards, setUserRewards] = useState(0);
  const [allTimeRewards, setAllTimeRewards] = useState(0);
  const [totalRewards, setTotalRewards] = useState(0);
  const [pairAddress, setPairAddress] = useState(null);

  // State for CoinDialog
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedToken, setSelectedToken] = useState(null);

  // Effect to calculate pair address when token is selected
  useEffect(() => {
    const calculatePairAddress = async () => {
      if (selectedToken && props.network.factory && props.network.weth) {
        try {
          // Get pair address from factory
          const pair = await props.network.factory.getPair(
            selectedToken.address,
            props.network.weth.address
          );
          setPairAddress(pair);
          console.log("Calculated pair address:", pair);
        } catch (error) {
          console.error("Error calculating pair address:", error);
          setPairAddress(null);
        }
      }
    };

    calculatePairAddress();
  }, [selectedToken, props.network.factory, props.network.weth]);

  useEffect(() => {
    if (selectedToken && pairAddress) {
      console.log("selectedToken updated: ", selectedToken);
      console.log("Using pair address:", pairAddress);

      const fetchRewards = async () => {
        console.log("Signer:: ", props.network.signer);
        console.log("Account:: ", props.network.account);
        try {
          await fetchR();
        } catch (error) {
          console.error("Error fetching rewards:", error);
        }
      };

      fetchRewards();
      const fetchInterval = setInterval(fetchRewards, 6000);
      return () => clearInterval(fetchInterval);
    }
  }, [selectedToken, pairAddress]);

  async function fetchR() {
    try {
      console.log("selectedtoken is:", selectedToken);
      console.log("using pair address:", pairAddress);
      const stats = await getStatsLOCK(
        pairAddress, // Use calculated pair address instead of token address
        props.network.router,
        props.network.signer
      );
      setUserRewards(stats[0]);
      setAllTimeRewards(stats[1]);
      setTotalRewards(stats[2]);
    } catch (error) {
      console.error("Error fetching rewards:", error);
    }
  }

  async function handleClaim() {
    try {
      await claimRewardsLOCK(
        pairAddress, // Use calculated pair address
        props.network.signer,
      );
      const stats = await getStatsLOCK(
        pairAddress,
        props.network.router,
        props.network.signer
      );
      setUserRewards(stats[0]);
      setAllTimeRewards(stats[1]);
      setTotalRewards(stats[2]);
    } catch (error) {
      console.error("Error claiming rewards:", error);
    }
  }

  const handleOpenDialog = () => {
    setDialogOpen(true);
  };

  const handleCloseDialog = async (address) => {
    setDialogOpen(false);

    if (address && props.network.signer) {
      try {
        console.log("closing dialog with addr", address);
        const data = await getBalanceAndSymbol(
          props.network.account,
          address,
          props.network.provider,
          props.network.signer,
          props.network.weth.address,
          props.network.coins
        );
        if (data != null) {
          console.log("setting selected token");
          setSelectedToken({
            address: address,
            symbol: data.symbol,
            balance: data.balance,
          });
        }
      } catch (err) {
        console.log("handleCloseERR: ", err);
      }
    }
  };

  return (
    <div className="fitcontent marginBot">
      <CoinDialog
        open={dialogOpen}
        onClose={handleCloseDialog}
        coins={props.network.coins}
        props={props.network.signer}
      />
      <div className="window scrollcontainer lg-padding rewardspage">
        <fieldset className="contentBox">
          <legend>Your rewards (USD)</legend>
          <div className="BalanceBox">
            <p className="BalanceBoxBody">{userRewards}</p>
          </div>
          {selectedToken && (
            <div>
              <p>{selectedToken.symbol}</p>
              {pairAddress && (
                <p className="lightweight">Pair: {pairAddress.slice(0, 6)}...{pairAddress.slice(-4)}</p>
              )}
            </div>
          )}
          <button className="metadatabuttoncontainer" onClick={handleOpenDialog}>
            <h6 className="unselectable metadatabutton">Select Token</h6>
          </button>

          <button 
            className="metadatabuttoncontainer" 
            onClick={handleClaim}
            disabled={!pairAddress}
          >
            <h6 className="metadatabutton">Claim</h6>
          </button>
        </fieldset>
        <fieldset className="contentBox">
          <legend>All time rewards</legend>
          <div className="BalanceBox">
            <p className="BalanceBoxBody">{allTimeRewards}</p>
          </div>
        </fieldset>
      </div>
    </div>
  );
}

export default RewardsLocker;
