import React, { useEffect } from "react";
import {makeStyles } from "@material-ui/core";
import { useSnackbar } from "notistack";
import { getBalanceAndSymbol, getReserves } from "../ethereumFunctions";

import { addLiquidity, quoteAddLiquidity } from "./LiquidityFunctions";

import CoinField from "../CoinSwapper/CoinField";
import COINS from "../constants/coins";
import CoinDialog from "../CoinSwapper/CoinDialog";
import WrongNetwork from "../Components/wrongNetwork";
import "../assets/styles.css";
import "../assets/styles2.css";
const styles = (theme) => ({
  paperContainer: {
    width: "40%",
    overflow: "wrap",
    marginTop: "25px",
  },
  fullWidth: {
    width: "95%",
  },
  values: {
    width: "50%",
  },

  hr: {
    width: "100%",
  },
});

const useStyles = makeStyles(styles);

function LiquidityDeployer(props) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  // Stores a record of whether their respective dialog window is open
  const [dialog1Open, setDialog1Open] = React.useState(false);
  const [dialog2Open, setDialog2Open] = React.useState(false);
  const [wrongNetworkOpen, setwrongNetworkOpen] = React.useState(false);
  const [isInitialized, setIsInitialized] = React.useState(false);

  // Stores data about their respective coin
  const [coin1, setCoin1] = React.useState({
    address: undefined,
    logo: undefined,
    symbol: undefined,
    balance: undefined,
  });
  const [coin2, setCoin2] = React.useState({
    address: undefined,
    logo: undefined,
    symbol: undefined,
    balance: undefined,
  });

  // Initialize ETH as default token1 only once
  useEffect(() => {
    if (!isInitialized && props.network && props.network.weth && props.network.weth.address) {
      getBalanceAndSymbol(
        props.network.account,
        props.network.weth.address,
        props.network.provider,
        props.network.signer,
        props.network.weth.address,
        props.network.coins
      ).then((data) => {
        const selectedCoin = COINS.get(props.network.chainID).find(
          (coin) => coin.address === props.network.weth.address
        );
        setCoin1({
          address: props.network.weth.address,
          symbol: data.symbol,
          balance: data.balance,
          logo: selectedCoin?.logo || null,
        });
        setIsInitialized(true);
      });
    }
  }, [props.network.weth, props.network.account, isInitialized]);

  // Update balances and reserves periodically
  useEffect(() => {
    const coinTimeout = setTimeout(() => {
      console.log("Checking balances & Getting reserves...");

      if (coin1.address && coin2.address && props.network.account) {
        getReserves(
          coin1.address,
          coin2.address,
          props.network.factory,
          props.network.signer,
          props.network.account
        ).then((data) => {
          setReserves([data[0], data[1]]);
          setLiquidityTokens(data[2]);
        });
      }

      if (coin1.address && props.network.account && !wrongNetworkOpen) {
        getBalanceAndSymbol(
          props.network.account,
          coin1.address,
          props.network.provider,
          props.network.signer,
          props.network.weth.address,
          props.network.coins
        ).then((data) => {
          setCoin1(prevCoin => ({
            ...prevCoin,
            balance: data.balance,
          }));
        });
      }
      if (coin2.address && props.network.account && !wrongNetworkOpen) {
        getBalanceAndSymbol(
          props.network.account,
          coin2.address,
          props.network.provider,
          props.network.signer,
          props.network.weth.address,
          props.network.coins
        ).then((data) => {
          setCoin2(prevCoin => ({
            ...prevCoin,
            balance: data.balance,
          }));
        });
      }
    }, 10000);

    return () => clearTimeout(coinTimeout);
  }, [coin1.address, coin2.address, props.network.account, wrongNetworkOpen]);

  const [reserves, setReserves] = React.useState(["0.0", "0.0"]);
  const [field1Value, setField1Value] = React.useState("");
  const [field2Value, setField2Value] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [liquidityTokens, setLiquidityTokens] = React.useState("");
  const [liquidityOut, setLiquidityOut] = React.useState([0, 0, 0]);

  // Switches the top and bottom coins, this is called when users hit the swap button or select the opposite
  // token in the dialog (e.g. if coin1 is TokenA and the user selects TokenB when choosing coin2)
  const switchFields = () => {
    // Only switch if coin2 is defined to prevent losing the WETH default
    if (coin2.address) {
      let oldField1Value = field1Value;
      let oldField2Value = field2Value;

      setCoin1(coin2);
      setCoin2(coin1);
      setField1Value(oldField2Value);
      setField2Value(oldField1Value);
      setReserves(reserves.reverse());
    }
  };
  // These functions take an HTML event, pull the data out and puts it into a state variable.
  const handleChange = {
    field1: (e) => {
      setField1Value(e.target.value);
    },
    field2: (e) => {
      setField2Value(e.target.value);
    },
  };

  // Turns the account's balance into something nice and readable
  const formatBalance = (balance, symbol) => {
    if (balance && symbol)
      return parseFloat(balance).toPrecision(8) + " " + symbol;
    else return "0.0";
  };

  // Turns the coin's reserves into something nice and readable
  const formatReserve = (reserve, symbol) => {
    if (reserve && symbol) return reserve + " " + symbol;
    else return "0.0";
  };

  // Determines whether the button should be enabled or not
  const isButtonEnabled = () => {
    // If both coins have been selected, and a valid float has been entered for both, which are less than the user's balances, then return true
    const parsedInput1 = parseFloat(field1Value);
    const parsedInput2 = parseFloat(field2Value);
    return (
      coin1.address &&
      coin2.address &&
      parsedInput1 !== NaN &&
      0 < parsedInput1 &&
      parsedInput2 !== NaN &&
      0 < parsedInput2 &&
      parsedInput1 <= coin1.balance &&
      parsedInput2 <= coin2.balance
    );
  };

  const deploy = () => {
    console.log("Attempting to deploy liquidity...");
    setLoading(true);

    addLiquidity(
      coin1.address,
      coin2.address,
      field1Value,
      field2Value,
      (field1Value*0.95).toFixed(6).toString(),
      (field2Value*0.95).toFixed(6).toString(),
      props.network.router,
      props.network.account,
      props.network.signer
    )
      .then(() => {
        setLoading(false);

        // If the transaction was successful, we clear to input to make sure the user doesn't accidental redo the transfer
        setField1Value("");
        setField2Value("");
        enqueueSnackbar("Deployment Successful", { variant: "success" });
      })
      .catch((e) => {
        setLoading(false);
        enqueueSnackbar("Deployment Failed (" + e.message + ")", {
          variant: "error",
          autoHideDuration: 10000,
        });
      });
  };

  // Called when the dialog window for coin1 exits
  const onToken1Selected = (address) => {
    // Close the dialog window
    setDialog1Open(false);

    // If the user inputs the same token, we want to switch the data in the fields
    if (address === coin2.address) {
      switchFields();
    }
    // We only update the values if the user provides a token
    else if (address) {
      // Getting some token data is async, so we need to wait for the data to return, hence the promise
      getBalanceAndSymbol(
        props.network.account,
        address,
        props.network.provider,
        props.network.signer,
        props.network.weth.address,
        props.network.coins
      ).then((data) => {
        const selectedCoin = COINS.get(props.network.chainID).find(coin => coin.address === address);
        setCoin1({
          address: address,
          symbol: data.symbol,
          balance: data.balance,
          logo: selectedCoin?.logo || null,
        });
      });
    }
  };

  // Called when the dialog window for coin2 exits
  const onToken2Selected = (address) => {
    // Close the dialog window
    setDialog2Open(false);

    // If the user inputs the same token, we want to switch the data in the fields
    if (address === coin1.address) {
      switchFields();
    }
    // We only update the values if the user provides a token
    else if (address) {
      // Getting some token data is async, so we need to wait for the data to return, hence the promise
      getBalanceAndSymbol(
        props.network.account,
        address,
        props.network.provider,
        props.network.signer,
        props.network.weth.address,
        props.network.coins
      ).then((data) => {
        const selectedCoin = COINS.get(props.network.chainID).find(coin => coin.address === address);
        setCoin2({
          address: address,
          symbol: data.symbol,
          balance: data.balance,
          logo: selectedCoin?.logo || null,
        });
      });
    }
  };

  // This hook is called when either of the state variables `coin1.address` or `coin2.address` change.
  // This means that when the user selects a different coin to convert between, or the coins are swapped,
  // the new reserves will be calculated.
  useEffect(() => {
    console.log(
      "Trying to get reserves between:\n" + coin1.address + "\n" + coin2.address
    );

    if (coin1.address && coin2.address && props.network.account) {
      getReserves(
        coin1.address,
        coin2.address,
        props.network.factory,
        props.network.signer,
        props.network.account
      ).then((data) => {
        setReserves([data[0], data[1]]);
        setLiquidityTokens(data[2]);
      });
    }
  }, [
    coin1.address,
    coin2.address,
    props.network.account,
    props.network.factory,
    props.network.signer,
  ]);

  // This hook is called when either of the state variables `field1Value`, `field2Value`, `coin1.address` or `coin2.address` change.
  // It will give a preview of the liquidity deployment.
  useEffect(() => {
    if (isButtonEnabled()) {
      console.log("Trying to preview the liquidity deployment");

      quoteAddLiquidity(
        coin1.address,
        coin2.address,
        field1Value,
        field2Value,
        props.network.factory,
        props.network.signer
      ).then((data) => {
        // console.log(data);
        console.log("TokenA in: ", data[0]);
        console.log("TokenB in: ", data[1]);
        console.log("Liquidity out: ", data[2]);
        setLiquidityOut([data[0], data[1], data[2]]);
      });
    }
  }, [
    coin1.address,
    coin2.address,
    field1Value,
    field2Value,
    props.network.factory,
    props.network.signer,
  ]);

  // This hook creates a timeout that will run every ~10 seconds, it's role is to check if the user's balance has
  // updated has changed. This allows them to see when a transaction completes by looking at the balance output.
  useEffect(() => {
    const coinTimeout = setTimeout(() => {
      console.log("Checking balances & Getting reserves...");

      if (coin1.address && coin2.address && props.network.account) {
        getReserves(
          coin1.address,
          coin2.address,
          props.network.factory,
          props.network.signer,
          props.network.account
        ).then((data) => {
          setReserves([data[0], data[1]]);
          setLiquidityTokens(data[2]);
        });
      }

      if (coin1.address && props.network.account && !wrongNetworkOpen) {
        getBalanceAndSymbol(
          props.network.account,
          coin1.address,
          props.network.provider,
          props.network.signer,
          props.network.weth.address,
          props.network.coins
        ).then((data) => {
          setCoin1({
            ...coin1,
            balance: data.balance,
          });
        });
      }
      if (coin2.address && props.network.account && !wrongNetworkOpen) {
        getBalanceAndSymbol(
          props.network.account,
          coin2.address,
          props.network.provider,
          props.network.signer,
          props.network.weth.address,
          props.network.coins
        ).then((data) => {
          setCoin2({
            ...coin2,
            balance: data.balance,
          });
        });
      }
    }, 10000);

    return () => clearTimeout(coinTimeout);
  });

  const [tooltipVisible, setTooltipVisible] = React.useState(false);

  return (
    <fieldset>
    <div className="window minvw overflowscroll scrollcontainer">
      {/* Liquidity deployer */}
      {/* Dialog Windows */}
      <CoinDialog
        open={dialog1Open}
        onClose={onToken1Selected}
        coins={props.network.coins}
        signer={props.network.signer}
      />
      <CoinDialog
        open={dialog2Open}
        onClose={onToken2Selected}
        coins={props.network.coins}
        signer={props.networksigner}
      />
      <WrongNetwork open={wrongNetworkOpen} />
      
      <div className="coinSelectSection">
      <p className="subtitles correctFont NoMargin NoPadding">
        Liquidity Deployer
      </p>
        <div className="fullwidth">
          <CoinField
            activeField={true}
            value={field1Value}
            onClick={() => setDialog1Open(true)}
            onChange={handleChange.field1}
            logo={coin1.logo !== undefined ? coin1.logo : null}
            symbol={coin1.symbol !== undefined ? coin1.symbol : "Select"}
            className="row"
          />
        </div>

        <div className="fullwidth">
          <CoinField
            activeField={true}
            value={field2Value}
            onClick={() => setDialog2Open(true)}
            onChange={handleChange.field2}
            logo={coin2.logo !== undefined ? coin2.logo : null}
            symbol={coin2.symbol !== undefined ? coin2.symbol : "Select"}
          />
        </div>
      </div>
      <div
        container
        direction="row"
        alignItems="center"
        justifyContent="space-around"
        spacing={4}
        className="balance"
      >
        <div
          container
          item
          className="values"
          direction="column"
          alignItems="center"
          spacing={5}
        >
          {/* Balance Display */}
          <p className="subtitles correctFont">Your Balances</p>
          <div>
            <div className="BalanceBox NoMargin NoPadding" style={{backgroundColor: "transparent"}}>
              <p className="BalanceBoxBody" >
                {formatBalance(coin1.balance, coin1.symbol)}
              </p>
            </div>
            <div className="BalanceBox" style={{backgroundColor: "transparent"}}>
              <p className="BalanceBoxBody" >
                {formatBalance(coin2.balance, coin2.symbol)}
              </p>
            </div>
          </div>

          {/* Reserves Display */}
          <p className="subtitles correctFont">Liquidity</p>
          <div container direction="row" justifyContent="space-between">
            <div className="BalanceBox" style={{backgroundColor: "transparent"}}>
              <p className="BalanceBoxBody" >
                {formatReserve(reserves[0], coin1.symbol)}
              </p>
            </div>
            <div className="BalanceBox" style={{backgroundColor: "transparent"}}>
              <p className="BalanceBoxBody" >
                {formatReserve(reserves[1], coin2.symbol)}
              </p>
            </div>
          </div>

          {/* Liquidity Tokens Display */}
        </div>
        <fieldset className="papercontainer">
          {/*Red  Display to show the quote */}
          {/* Tokens in */}
          <p className="subtitles correctFont">Tokens in</p>
          <div className="tokeninrow">
            <p>{formatBalance(liquidityOut[0], coin1.symbol)}</p>
            <div>
              <p>{formatBalance(liquidityOut[1], coin2.symbol)}</p>
            </div>
          </div>
          <hr></hr>

          <p className="subtitles correctFont">Your Liquidity Pool Tokens</p>
          <div className="balanceboxsm">
            <p>{formatReserve(liquidityTokens, "VISTA-LP")}</p>
          </div>

          {/* Liquidity Tokens Display */}
          <hr></hr>

          <p className="subtitles correctFont">Liquidity Pool Tokens Out</p>
          <p>{formatReserve(liquidityOut[2], "VISTA-LP")}</p>
        </fieldset>
      </div>
      <p
        style={{
          fontSize: "11px",
          textAlign: "center",
          opacity: 0.75,
          marginTop: 15,
          lineHeight: 1.3,
        }}
      >
        Do not forget to claim rewards before <br />
        adding/removing LP else they will be lost.
      </p>      
    </div>
    <button
        loading={loading}
        valid={isButtonEnabled()}
        success={false}
        fail={false}
        onClick={deploy}
        className="metadatabuttoncontainer"
      >
        <h6 className="metadatabutton">Deploy</h6>
        {/* <img
          src={deployer}
          alt="Deploye"
          className="goldCoin  NoMargin NoPadding"
        ></img> */}
      </button>
    </fieldset>
  );
}

export default LiquidityDeployer;
