import { useState, useEffect, useRef } from "react";
import { ethers } from "ethers";
import {
  getAccount,
  getFactory,
  getRouter,
  getNetwork,
  getWeth,
} from "../ethereumFunctions";
import COINS from "../constants/coins";
import * as chains from "../constants/chains";

export const useWeb3Connection = () => {
  const [isConnected, setConnected] = useState(false);
  const [network, setNetwork] = useState({
    provider: null,
    signer: null,
    account: null,
    chainID: null,
    coins: [],
    router: null,
    factory: null,
    weth: null,
  });

  const backgroundListener = useRef(null);

  async function setupConnection() {
    try {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();

      const account = await getAccount();
      const chainID = await getNetwork(provider);

      if (chains.networks.includes(chainID)) {
        const router = await getRouter(
          chains.routerAddress.get(chainID),
          signer
        );
        const coins = COINS.get(chainID);
        const wethAddress = await router.WETH();
        const weth = getWeth(wethAddress, signer);
        coins[0].address = wethAddress;

        const factoryAddress = await router.factory();
        const factory = getFactory(factoryAddress, signer);

        setNetwork({
          provider,
          signer,
          account,
          chainID,
          coins,
          router,
          factory,
          weth,
        });
        setConnected(true);
      } else {
        alert("Wrong network.");
        setConnected(false);
      }
    } catch (e) {
      console.log("Error in setupConnection:", e);
      setConnected(false);
    }
  }

  async function createListener() {
    return setInterval(async () => {
      try {
        const account = await getAccount();
        if (account !== network.account) {
          await setupConnection();
        }
      } catch (e) {
        console.log("Error in listener:", e);
        setConnected(false);
        await setupConnection();
      }
    }, 5000);
  }

  useEffect(() => {
    setupConnection();

    if (backgroundListener.current != null) {
      clearInterval(backgroundListener.current);
    }
    backgroundListener.current = createListener();

    return () => clearInterval(backgroundListener.current);
  }, []);

  return { isConnected, network };
};