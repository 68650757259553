import * as chains from "./chains";
import ethereum from "../assets/images/ethereumlogo.png";
import ethervista from "../assets/images/logo.png";
import degenads from "../assets/images/degenlogo.png";
import bonzi from "../assets/images/BonziIcon.webp";
import dark from "../assets/images/dark.webp";
import clippy from "../assets/images/clippylogo.png";
import rover from "../assets/images/roverlogo.png";
import ninja from "../assets/images/ninjaicon.png";
import lime from "../assets/images/lime.png";
import pupil from "../assets/images/pupil.webp";
import cabi from "../assets/images/cabi.jpg";
import sharky from "../assets/images/sharky.jpg";
// If you add coins for a new network, make sure Weth address (for the router you are using) is the first entry
const MAINNETCoins = [
  {
    name: "Ether",
    abbr: "ETH",
    address: "", // Weth address is fetched from the router
    logo: ethereum,
  },
  {
    name: "Ethervista",
    abbr: "VISTA",
    address: "0xC9bCa88B04581699fAb5aa276CCafF7Df957cbbf", // Weth address is fetched from the router
    logo: ethervista,
  },
  {
    name: "DegenAds",
    abbr: "DEGEN",
    address: "0x7b20798866Fe3320EC5395e9978a3C98195c7c36",
    logo: degenads,
  },
  {
    name: "Pup Is Looking",
    abbr: "PUPIL",
    address: "0xd81E97027c21366Ead8E37428b3c033E95A7ADC2",
    logo: pupil,
  },
  {
    name: "CABI",
    abbr: "CABI",
    address: "0x6A064c1B4A0432c5d7Df441759E4E256F21af8BA",
    logo: cabi,
  },
  {
    name: "Sharky",
    abbr: "SHARKY",
    address: "0xE5f9f6d8a6fDa962cC7448fE903dbae89deA4eD8",
    logo: sharky,
  },
  {
    name: "Bonzi",
    abbr: "BONZI",
    address: "0xD6175692026BcD7Cb12A515e39cF0256eF35cb86", // Weth address is fetched from the router
    logo: bonzi,
  },
  {
    name: "dark",
    abbr: "dark",
    address: "0x6F0D7bA19646D0eF82bf8f78f3A7456aD5B9481F",
    logo: dark,
  },
  {
    name: "Office Helper Clippy",
    abbr: "CLIPPY",
    address: "0x6304563F6639c077B519209f38611C0F67D6f10d", // Weth address is fetched from the router
    logo: clippy,
  },
  {
    name: "Rover",
    abbr: "ROVER",
    address: "0x6aF84e3e9Fa8486b5cBb67c55ED1E7D9372a6d23", // Weth address is fetched from the router
    logo: rover,
  },
  {
    name: "Ninjacat",
    abbr: "NINJA",
    address: "0xF1a732FEEEb43450E4B35b54ea9D04eD994391AA",
    logo: ninja,
  },
  {
    name: "Lime Audio",
    abbr: "LIME",
    address: "0x4Bd5E8Cd6A7642e93368B1eC900Eb6B02B6D5351",
  },
];

const ARBITRUMCoins = [
  {
    name: "Ether",
    abbr: "ETH",
    address: "", // Weth address is fetched from the router
  },
];

const BASECoins = [
  {
    name: "Ether",
    abbr: "ETH",
    address: "", // Weth address is fetched from the router
  },
  {
    name: "Dansen",
    abbr: "DANSEN",
    address: "0x8346DE47EF1542cba86570FBD78471ee6E803E11",
  },
];

/*
const SEPOLIACoins = [
  {
    name: "Ether",
    abbr: "ETH",
    address: "", // Weth address is fetched from the router
  },
  {
    name: "Ethervista",
    abbr: "VISTA",
    address: "0x637fe414b404C1274B2aCE377d016529B41dcDd6", // Weth address is fetched from the router
  },
  {
    name: "Tether USD",
    abbr: "USDT",
    address: "0x7169D38820dfd117C3FA1f22a697dBA58d90BA06",
  },
];
*/

const COINS = new Map();
COINS.set(chains.ChainId.MAINNET, MAINNETCoins);
//COINS.set(chains.ChainId.SEPOLIA, SEPOLIACoins);
COINS.set(chains.ChainId.BASE, BASECoins);
COINS.set(chains.ChainId.ARBITRUM, ARBITRUMCoins);

export default COINS;
