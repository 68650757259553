import React, { useState, useEffect } from "react";
import "../assets/styles.css";
import "../assets/styles2.css";
import { getBalanceAndSymbol, getStatsSTAKE, claimRewardsSTAKE } from "../ethereumFunctions";

function RewardsStake(props) {
  const hardcodedAddress = "0xfdd05552F1377aA488AFed744c8024358AF02041"; // Hardcoded address

  const [userRewards, setUserRewards] = useState(0);
  const [allTimeRewards, setAllTimeRewards] = useState(0);
  const [totalRewards, setTotalRewards] = useState(0);
  const [tokenData, setTokenData] = useState(null); // Store token symbol and balance here

  useEffect(() => {
    const fetchRewards = async () => {
      console.log("Signer:: ", props.network.signer);
      console.log("Account:: ", props.network.account);
      try {
        await fetchR();
      } catch (error) {
        console.error("Error fetching rewards:", error);
      }
    };

    fetchRewards();
    const fetchInterval = setInterval(fetchRewards, 6000);
    return () => clearInterval(fetchInterval);
  }, []);

  useEffect(() => {
    const fetchTokenData = async () => {
      if (props.network.account && props.network.signer) {
        try {
          const data = await getBalanceAndSymbol(
            props.network.account,
            hardcodedAddress,
            props.network.provider,
            props.network.signer,
            props.network.weth.address,
            props.network.coins
          );
          if (data != null) {
            setTokenData({
              address: hardcodedAddress,
              symbol: data.symbol,
              balance: data.balance,
            });
          }
        } catch (err) {
          console.log("Error fetching token data: ", err);
        }
      }
    };

    fetchTokenData();
  }, [props.network]);

  async function fetchR() {
    try {
      const stats = await getStatsSTAKE(
        props.network.router,
        props.network.signer
      );
      setUserRewards(stats[0]);
      setAllTimeRewards(stats[1]);
      setTotalRewards(stats[2]);
    } catch (error) {
      console.error("Error fetching rewards:", error);
    }
  }

  async function handleClaim() {
    await claimRewardsSTAKE(props.network.signer);
    const stats = await getStatsSTAKE(props.network.router, props.network.signer);
    setUserRewards(stats[0]);
    setAllTimeRewards(stats[1]);
    setTotalRewards(stats[2]);
  }

  return (
    <div className="fitcontent marginBot">
      <div className="window scrollcontainer lg-padding rewardspage">
        <fieldset className="contentBox">
          <legend>Your rewards (USD)</legend>
          <div className="BalanceBox">
            <p className="BalanceBoxBody">{userRewards}</p>
          </div>
          {tokenData && (
            <div>
            </div>
          )}

          <button className="metadatabuttoncontainer" onClick={handleClaim}>
            <h6 className="metadatabutton">Claim</h6>
          </button>
        </fieldset>
        <fieldset className="contentBox">
          <legend>All time rewards</legend>
          <div className="BalanceBox">
            <p className="BalanceBoxBody">{allTimeRewards}</p>
          </div>
        </fieldset>
        <fieldset className="contentBox">
          <legend>Current Pool</legend>
          <div className="BalanceBox">
            <p className="BalanceBoxBody">{totalRewards}</p>
          </div>
        </fieldset>
      </div>
    </div>
  );
}

export default RewardsStake;
