import React, { useState, useEffect } from "react";
import "../assets/styles.css";
import "../assets/styles2.css";
import {
  getBalanceAndSymbol,
  getMetadata,
  getLivedata,
} from "../ethereumFunctions";
import CoinDialog from "../CoinSwapper/CoinDialog";
import notfound from "../assets/images/channels3.png";
import tokengif from "../assets/images/tokenanalytics.gif";
function Analytics(props) {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedToken, setSelectedToken] = useState(null);
  const [metadata, setMetadata] = useState(null); // State to store metadata
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (selectedToken && props.network) {
      console.log("selectedToken updated: ", selectedToken);
      let meta;

      const fetchMetadata = async () => {
        try {
          setIsLoading(true); // Set loading to true before fetching new data
          const fetchedMetadata = await getMetadata(
            selectedToken.address,
            props.network?.router,
            props.network?.factory,
            props.network?.signer
          );
          meta = fetchedMetadata;
          setMetadata(fetchedMetadata);
        } catch (error) {
          console.error("Error fetching metadata:", error);
        } finally {
          setIsLoading(false); // Set loading to false once the data is fetched
        }
      };

      const fetchLivedata = async () => {
        try {
          setIsLoading(true); // Set loading to true before fetching new data
          //const fetchedMetadata =
          await getLivedata(
            selectedToken.address,
            props.network?.router,
            props.network?.factory,
            props.network?.signer
          ).then((fetchedMetadata) => {
            console.log(fetchedMetadata.supply);
            meta.supply = fetchedMetadata.supply;
            meta.price = fetchedMetadata.price;
            meta.totalRewardsUSD = fetchedMetadata.totalRewardsUSD;
            meta.marketcap = fetchedMetadata.marketcap;
            meta.total_liquidity = fetchedMetadata.total_liquidity;
          });

          // Once new data is fetched, update the main state
          setMetadata(meta);
        } catch (error) {
          console.error("Error fetching metadata:", error);
        } finally {
          setIsLoading(false); // Set loading to false once the data is fetched
        }
      };

      fetchMetadata();
      const fetchInterval = setInterval(fetchLivedata, 6000); // Adjust the interval as needed (e.g., 6000ms = 6 seconds)
      return () => clearInterval(fetchInterval);
    }
  }, [selectedToken, props.network]);

  const handleOpenDialog = () => {
    setDialogOpen(true);
  };

  const handleCloseDialog = async (address) => {
    setDialogOpen(false);

    if (address && props.network?.signer) {
      try {
        console.log("Closing dialog with addr:", address);
        const data = await getBalanceAndSymbol(
          props.network.account,
          address,
          props.network.provider,
          props.network.signer,
          props.network.weth.address,
          props.network.coins
        );
        console.log(data);

        if (data != null) {
          console.log("Setting selected token");
          const selectedToken = {
            address: address,
            symbol: data.symbol,
            balance: data.balance,
          };
          setSelectedToken(selectedToken);

          // Fetch metadata after setting the selected token
          const fetchedMetadata = await getMetadata(
            selectedToken.address,
            props.network?.router,
            props.network?.factory,
            props.network?.signer
          );

          // Log the fetched metadata to the console
          /*
          console.log("Fetched Metadata:", {
            symbol: fetchedMetadata.symbol,
            name: fetchedMetadata.name,
            price: fetchedMetadata.price,
            marketcap: fetchedMetadata.marketcap,
            total_liquidity: fetchedMetadata.total_liquidity,
            eth_price: fetchedMetadata.eth_price,
            supply: fetchedMetadata.supply,
            renounced: fetchedMetadata.renounced,
            totalRewardsUSD: fetchedMetadata.totalRewardsUSD,
            buyFee: fetchedMetadata.buyFee,
            sellFee: fetchedMetadata.sellFee,
          });
          */

          // Set the fetched metadata in the state
          setMetadata(fetchedMetadata);
        }
      } catch (err) {
        console.log("Error in handleCloseDialog:", err);
      }
    }
  };

  return (
    <div className="fitcontent">
      <CoinDialog
        open={dialogOpen}
        onClose={handleCloseDialog}
        coins={props.network?.coins || []}
        props={props.network?.signer}
      />
      <div className="analysticsspage">
        <fieldset>
          <legend>
            <img
              src={tokengif}
              alt="Token Analytics"
              className="token-analytics-gif"
            />
          </legend>
          {!selectedToken && <p>Token info will appear here.</p>}
          {selectedToken && isLoading && !metadata && <p>Loading...</p>}
          {/* Display metadata if available */}
          {metadata && (
            <div className="metadata-display">
              <h4>Metadata for {selectedToken.symbol}</h4>
              <img
                src={metadata?.metadata?.[1] || notfound}
                alt="NO COVER IMAGE FOUND"
                className="metadata-cover"
                onError={(e) => {
                  e.target.onerror = null; // Prevent infinite loop in case notfound image fails
                  e.target.src = notfound;
                }}
              />

              <section className="metadata-titlesection">
                <p className="vertical-metadata">
                  <strong>Name</strong> {metadata.name}
                </p>
                <p className="vertical-metadata">
                  <strong>Ticker</strong>{" "}$
                  {metadata.symbol || "Error loading... Please refresh."}
                </p>
              </section>
              <fieldset className="smallfieldset">
                <legend>Analytics</legend>
                <p>
                  <strong>Created:</strong> {metadata.creation_date}
                </p>
                <p>
                  <strong>Description:</strong>{" "}
                  {metadata.metadata[2] || "Error loading... Please refresh."}
                </p>
                <p>
                  <strong>Price:</strong>{" "}$
                  {metadata.price || "Error loading... Please refresh."}
                </p>
                <p>
                  <strong>Supply:</strong>{" "}
                  {metadata.supply || "Error loading... Please refresh."}
                </p>

                <p>
                  <strong>Total Rewards:</strong>{" "}
                  {metadata.totalRewardsUSD ||
                    "Error loading... Please refresh."}
                </p>

                <p>
                  <strong>FDV:</strong>{" "}$
                  {metadata.marketcap || "Error loading... Please refresh."}
                </p>
                <p>
                  <strong>Liquidity:</strong>{" "}$
                  {metadata.total_liquidity ||
                    "Error loading... Please refresh."}
                </p>

                <p>
                  <strong>Renounced:</strong>{" "}
                  {metadata.renounced ? "Yes" : "No"}
                </p>
                <section className="metadata-feesection">
                  <p className="vertical-metadata">
                    <strong>Buy Fee</strong>{" "}
                    ${metadata.buyFee || "Error loading... Please refresh."}
                  </p>
                  <p className="vertical-metadata">
                    <strong>Sell Fee</strong>{" "}
                    ${metadata.sellFee || "Error loading... Please refresh."}
                  </p>
                </section>
              </fieldset>
              <fieldset className="smallfieldset">
                <legend>Socials</legend>
                <section className="vertical-socials">
                  <a href={metadata.metadata[0] || "#"}>
                    <p>{metadata.metadata[0]}</p>
                  </a>
                  <a href={metadata.metadata[3] || "#"}>
                    <p>{metadata.metadata[3]}</p>
                  </a>
                  <a href={metadata.metadata[4] || "#"}>
                    <p>{metadata.metadata[4]}</p>
                  </a>
                </section>
              </fieldset>
            </div>
          )}
        </fieldset>

        {selectedToken && <div></div>}
        <button className="rewardsselect" onClick={handleOpenDialog}>
          <h6 className="unselectable EtherVistaSubTitle">Select Token</h6>
        </button>
      </div>
    </div>
  );
}

export default Analytics;
