import React, { useState, useEffect } from "react"; // Added useEffect to the import statement
import "../assets/styles2.css";


export function SwitchButton(props) {
  const { setDeploy } = props;
  const [selectedButton, setSelectedButton] = useState("add-button");

  const changeStyles = (id) => {
    const buttons = document.querySelectorAll(".liqbuttons");
    buttons.forEach((button) => {
      if (button.id === id) {
        button.setAttribute("aria-selected", "true");
      } else {
        button.removeAttribute("aria-selected");
      }
    });
  };

  // Set initial state of aria-selected for "Add Liquidity" button
  useEffect(() => { // Changed React.useEffect to useEffect
    const addLiquidityButton = document.getElementById("add-button");
    if (addLiquidityButton) {
      addLiquidityButton.setAttribute("aria-selected", "true");
    }
  }, []);

  return (
    <div>
      <menu className="liqmenu" role="tablist">
        <li
          id="add-button"
          // className="liqbuttons"
          className={`liqbuttons ${selectedButton === "add-button" ? 'selected' : ''}`}
          aria-selected={selectedButton === "add-button" ? "true" : "false"}
          onClick={() => {
            setDeploy(true);
            setSelectedButton("add-button");
            changeStyles("add-button");
          }}
        >
          Add Liquidity
        </li>

        <li
          id="remove-button"
          // className="liqbuttons"
          className={`liqbuttons ${selectedButton === "remove-button" ? 'selected' : ''}`}
          aria-selected={selectedButton === "remove-button" ? "true" : "false"}
          onClick={() => {
            setDeploy(false);
            setSelectedButton("remove-button");
            changeStyles("remove-button");
          }}
        >
          Remove Liquidity
        </li>
      </menu>
    </div>
  );
}

export default SwitchButton