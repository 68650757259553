import React from "react";


function ConnectWalletPage() {
  return (
    <div>
      <div
        container
        direction="row"
        justifyContent="center"
        alignItems="flex-end"
      ></div>
      <fieldset className="nowalletp">
        <p> Connect wallet for access</p>
        <img
          src="https://win98icons.alexmeub.com/icons/png/laptop_infrared_2-5.png"
          alt="Windows Logo"
        />
      </fieldset>
    </div>
  );
}

export default ConnectWalletPage;
