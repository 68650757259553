import React from "react";
import BonziImage from "../../assets/images/BonziIcon.webp";

const BonziIcon = () => {
  return (
    <img
      src={BonziImage}
      alt="Bonzi Logo"
      width="50"
      height="50"
      style={{ pointerEvents: "none" }}
    />
  );
};

export default BonziIcon;
