import React, { useEffect } from "react";

import { useSnackbar } from "notistack";
import {
  getAmountOut,
  getBalanceAndSymbol,
  swapTokens,
  getReserves,
  isWhitelisted,
} from "../ethereumFunctions";
import CoinField from "./CoinField";
import CoinDialog from "./CoinDialog";
import WrongNetwork from "../Components/wrongNetwork";
import COINS from "../constants/coins";
import "../assets/styles.css";
import "../assets/styles2.css";
import settingicon from "../assets/images/setting.png";
import swapicon from "../assets/images/swap.png";
import arrowbot from "../assets/images/arrow.png";

function CoinSwapper(props) {
  const { enqueueSnackbar } = useSnackbar();

  const [dialog1Open, setDialog1Open] = React.useState(false);
  const [dialog2Open, setDialog2Open] = React.useState(false);
  const [wrongNetworkOpen, setwrongNetworkOpen] = React.useState(false);
  const [isInitialized, setIsInitialized] = React.useState(false);


  const [coin1, setCoin1] = React.useState({
    address: undefined,
    logo: undefined,
    symbol: undefined,
    balance: undefined,
  });
  const [coin2, setCoin2] = React.useState({
    address: undefined,
    logo: undefined,
    symbol: undefined,
    balance: undefined,
  });

  // Initialize ETH as default token1 only once
  useEffect(() => {
    if (!isInitialized && props.network && props.network.weth && props.network.weth.address) {
      getBalanceAndSymbol(
        props.network.account,
        props.network.weth.address,
        props.network.provider,
        props.network.signer,
        props.network.weth.address,
        props.network.coins
      ).then((data) => {
        const selectedCoin = COINS.get(props.network.chainID).find(
          (coin) => coin.address === props.network.weth.address
        );
        setCoin1({
          address: props.network.weth.address,
          symbol: data.symbol,
          balance: data.balance,
          logo: selectedCoin?.logo || null,
        });
        setIsInitialized(true);
      });
    }
  }, [props.network.weth, props.network.account, isInitialized]);

// Update only balances in the periodic refresh
useEffect(() => {
  const coinTimeout = setTimeout(() => {
    if (coin1.address && props.network.account && !wrongNetworkOpen) {
      getBalanceAndSymbol(
        props.network.account,
        coin1.address,
        props.network.provider,
        props.network.signer,
        props.network.weth.address,
        props.network.coins
      ).then((data) => {
        setCoin1(prevCoin => ({
          ...prevCoin,
          balance: formatToFourDecimals(data.balance),
        }));
      });
    }
    if (coin2.address && props.network.account && !wrongNetworkOpen) {
      getBalanceAndSymbol(
        props.network.account,
        coin2.address,
        props.network.provider,
        props.network.signer,
        props.network.weth.address,
        props.network.coins
      ).then((data) => {
        setCoin2(prevCoin => ({
          ...prevCoin,
          balance: formatToFourDecimals(data.balance),
        }));
      });
    }
  }, 12000);

  return () => clearTimeout(coinTimeout);
}, [coin1.address, coin2.address, props.network.account, wrongNetworkOpen]);


  const [reserves, setReserves] = React.useState(["0", "0"]);
  const [field1Value, setField1Value] = React.useState("");
  const [field2Value, setField2Value] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [isTokenSafe, setIsTokenSafe] = React.useState();

  const switchFields = () => {
    setCoin1(coin2);
    setCoin2(coin1);
    setField1Value("");
    setReserves(reserves.reverse());
  };

  const handleChange = {
    field1: (e) => {
      setField1Value(e.target.value);
    },
  };

  const formatToFourDecimals = (value) => {
    if (!value) return "0.0000";
    const [integerPart, decimalPart = ""] = value.toString().split(".");
    const paddedDecimal = decimalPart.padEnd(4, "0").slice(0, 4);
    return `${integerPart}.${paddedDecimal}`;
  };

  const handleMaxClick = () => {
    if (coin1.balance) {     
      const formattedBalance = formatToFourDecimals(coin1.balance);
      setField1Value(formattedBalance);
    }
  };

  const formatBalance = (balance, symbol) => {
    if (balance && symbol) return formatToFourDecimals(parseFloat(balance));
    else return "0";
  };

  const formatReserve = (reserve) => {
    if (reserve) return reserve;
    else return "0";
  };

  const isButtonEnabled = () => {
    const parsedInput1 = parseFloat(field1Value);
    const parsedInput2 = parseFloat(field2Value);
    return (
      coin1.address &&
      coin2.address &&
      !isNaN(parsedInput1) &&
      !isNaN(parsedInput2) &&
      0 < parsedInput1 &&
      parsedInput1 <= coin1.balance &&
      (coin1.address === props.network.weth.address ||
        coin2.address === props.network.weth.address)
    );
  };

  const onToken1Selected = (address) => {
    setDialog1Open(false);

    if (address === coin2.address) {
      switchFields();
    } else if (address) {
      getBalanceAndSymbol(
        props.network.account,
        address,
        props.network.provider,
        props.network.signer,
        props.network.weth.address,
        props.network.coins
      ).then((data) => {
        const selectedCoin = COINS.get(props.network.chainID).find(coin => coin.address === address);
        setCoin1({
          address: address,
          symbol: data.symbol,
          balance: data.balance,
          logo: selectedCoin?.logo || null,
        });
      });
    }
  };

  const [showSlippageDiv, setShowSlippageDiv] = React.useState(false);
  const [slippageValue, setSlippageValue] = React.useState(1);

  const toggleSlippageDiv = () => {
    setShowSlippageDiv(!showSlippageDiv);
  };

  const slippageDiv = (
    <div className="slippageDiv window">
      <div class="title-bar">
        <div class="title-bar-text">Select slippage amount</div>
        <div class="title-bar-controls">
          <button aria-label="Close" onClick={toggleSlippageDiv}></button>
        </div>
      </div>

      <div class="field-row rangevalue">
        <label for="range26">0</label>
        <input
          id="range26"
          type="range"
          min="0"
          max="100"
          value={slippageValue}
          onChange={(e) => setSlippageValue(e.target.value)}
        />
        <label for="range27">100</label>
      </div>
      <p>Slippage : {slippageValue} %</p>
    </div>
  );

  const onToken2Selected = async (address) => {
    setDialog2Open(false);

    if (address === coin1.address) {
      switchFields();
    } else if (address) {
      getBalanceAndSymbol(
        props.network.account,
        address,
        props.network.provider,
        props.network.signer,
        props.network.weth.address,
        props.network.coins
      ).then(async (data) => {
        const selectedCoin = COINS.get(props.network.chainID).find(coin => coin.address === address);
        setCoin2({
          address: address,
          symbol: data.symbol,
          balance: data.balance,
          logo: selectedCoin?.logo || null,
        });
      });
    }
  };

  useEffect(() => {
    if (
      coin1.address &&
      coin1.address.length === 42 &&
      coin2.address &&
      coin2.address.length === 42
    ) {
      (async () => {
        let bool = await isWhitelisted(
          coin1.address,
          coin2.address,
          props.network.signer
        );
        setIsTokenSafe(bool);
      })();
    }
  }, [coin2.address, coin1.address, props.network.signer]);

  const truncateToFourDecimals = (value) => {
    if (value === '' || value == null || isNaN(value)) return '0';
    const [integerPart, decimalPart = ''] = value.toString().split('.');
    const truncatedDecimal = decimalPart.slice(0, 4);
    return `${integerPart}${truncatedDecimal ? '.' + truncatedDecimal : ''}`;
  };

  const swap = () => {
    console.log("Attempting to swap tokens...");
    setLoading(true);

    const truncatedValue = truncateToFourDecimals(field1Value);
    console.log("Swap value (truncated to 4 decimals):", truncatedValue);

    swapTokens(
      coin1.address,
      coin2.address,
      truncatedValue,
      props.network.router,
      props.network.account,
      props.network.signer,
      props.network.factory,
      slippageValue
    )
      .then(() => {
        setLoading(false);
        setField1Value("");
        enqueueSnackbar("Transaction Successful", { variant: "success" });
      })
      .catch((e) => {
        setLoading(false);
        enqueueSnackbar("Transaction Failed (" + e.message + ")", {
          variant: "error",
          autoHideDuration: 40000,
        });
      });
  };

  useEffect(() => {
    console.log(
      "Trying to get Reserves between:\n" + coin1.address + "\n" + coin2.address
    );

    if (coin1.address && coin2.address) {
      getReserves(
        coin1.address,
        coin2.address,
        props.network.factory,
        props.network.signer,
        props.network.account,
        props.network.router
      ).then((data) => setReserves(data));
    }
  }, [
    coin1.address,
    coin2.address,
    props.network.account,
    props.network.factory,
    props.network.router,
    props.network.signer,
  ]);

  useEffect(() => {
    if (isNaN(parseFloat(field1Value))) {
      setField2Value("");
    } else if (parseFloat(field1Value) && coin1.address && coin2.address) {
      getAmountOut(
        coin1.address,
        coin2.address,
        field1Value,
        props.network.router,
        props.network.signer
      )
        .then((amount) => setField2Value(amount.toString()))
        .catch((e) => {
          console.log(e);
          setField2Value("NA");
        });
    } else {
      setField2Value("");
    }
  }, [field1Value, coin1.address, coin2.address]);

  useEffect(() => {
    const coinTimeout = setTimeout(() => {
      console.log("props: ", props);
      console.log("Checking balances...");

      if (coin1.address && coin2.address) {
        getReserves(
          coin1.address,
          coin2.address,
          props.network.factory,
          props.network.signer,
          props.network.account,
          props.network.router
        ).then((data) => setReserves(data));
      }

      if (coin1.address && props.network.account && !wrongNetworkOpen) {
        getBalanceAndSymbol(
          props.network.account,
          coin1.address,
          props.network.provider,
          props.network.signer,
          props.network.weth.address,
          props.network.coins
        ).then((data) => {
          setCoin1({
            ...coin1,
            balance: formatToFourDecimals(data.balance),
          });
        });
      }
      if (coin2.address && props.network.account && !wrongNetworkOpen) {
        console.log("setting coin2");
        getBalanceAndSymbol(
          props.network.account,
          coin2.address,
          props.network.provider,
          props.network.signer,
          props.network.weth.address,
          props.network.coins
        ).then(async (data) => {
          setCoin2({
            ...coin2,
            balance: formatToFourDecimals(data.balance),
          });
        });
      }
    }, 12000);

    return () => clearTimeout(coinTimeout);
  });

  React.useEffect(() => {
    if (isTokenSafe === true) {
      setIsTokenSafe(true); // Token is safe
    } else if (isTokenSafe === false) {
      setIsTokenSafe(false); // Token is not safe
    } else {
      setIsTokenSafe(null); // Default to null if no value
    }
  }, [isTokenSafe]);

  const [isDisplayedDetails, setIsDisplayedDetails] = React.useState(false);
  const toggleDetails = () => {
    setIsDisplayedDetails(!isDisplayedDetails);
  };

  return (
    <div className="fitcontent paddingBot">
      <CoinDialog
        open={dialog1Open}
        onClose={onToken1Selected}
        coins={props.network.coins}
        props={props.network.signer}
      />
      <CoinDialog
        open={dialog2Open}
        onClose={onToken2Selected}
        coins={props.network.coins}
        signer={props.network.signer}
      />
      <WrongNetwork open={wrongNetworkOpen} />

      <div className="window scrollcontainer lg-padding" role="tabpanel">
        

        <fieldset>
          {showSlippageDiv && slippageDiv}
          <div className="container-input-swapicon">
            <div className="window-body graybg reduced-space first" style={{marginBottom: "-2px"}}>
              <CoinField
                activeField={true}
                value={field1Value}
                onClick={() => setDialog1Open(true)}
                onChange={handleChange.field1}
                logo={coin1.logo !== undefined ? coin1.logo : null}
                symbol={coin1.symbol !== undefined ? coin1.symbol : "Select"}
                // //////// MODIFICATION///////////
                showMaxButton={true}
                onMaxClick={handleMaxClick}
              />
            </div>
            <div className="switchButtonContainer second">
                <img 
                onClick={switchFields}
                className="switchButton"
                src={swapicon}
                alt="Switch" 
                style={{ width: "37px" }} />
            </div>          
            <div className="window-body graybg reduced-space third" style={{marginTop: "-2px"}}>
              <CoinField
                activeField={false}
                value={field2Value}
                onClick={() => setDialog2Open(true)}
                logo={coin2.logo !== undefined ? coin2.logo : null}
                symbol={coin2.symbol !== undefined ? coin2.symbol : "Select"}
                // ////////MODIFICATION/////////
                showMaxButton={false}
              />
            </div>
          </div>
          <div className="slippage-container-text">
            <p className="slippagevalue">Slippage : {slippageValue} %
              <img
              src={settingicon}
              alt="Slippage Icon"
              className="slippageIcon"
              onClick={toggleSlippageDiv}
              style={{ width: "30px" }}
              />
            </p>
            <p className="lightweight safety">
              {isTokenSafe === null
                ? null
                : isTokenSafe
                ? "Token is safe. Note: The creator could still hold a lot of tokens"
                : "This token may or may not be safe; DYOR"}
            </p>
          </div>

          
          <div className="dropdownArrow-container">
              <img 
                src={arrowbot} alt="show details" 
                style={{ maxWidth: "35px", maxHeight: "25px", cursor: "pointer" }}
                onClick={toggleDetails}
                title="Show details" 
              />
          </div>
          
        </fieldset>

        <fieldset className="contentBox" style={{
                      display: isDisplayedDetails ? 'flex' : 'none',
                      flexDirection: "column"                      
                    }}
        >          
          <p className="subtitles correctFont">Your Balances</p>
          <div className="BalanceBoxes">
            <div className="container-tokenBalance">
              <p style={{fontSize: "12px", textDecoration: "underline dotted" }}>{coin1.symbol}</p>
              <div item>
                <p className="BalanceBoxBody BalanceBoxDetails" style={{fontSize: "12px"}}>
                  {formatBalance(coin1.balance, coin1.symbol)}
                </p>
              </div>
            </div>
            <div className="container-tokenBalance">
              <p style={{fontSize: "12px", textDecoration: "underline dotted" }}>{coin2.symbol}</p>
              <div item>                           
                <p className="BalanceBoxBody BalanceBoxDetails" style={{fontSize: "12px"}}>
                  {formatBalance(coin2.balance, coin2.symbol)}
                </p>
              </div>
            </div>
          </div>

          <p className="subtitles correctFont">Liquidity</p>
          <div className="BalanceBoxes">
            <div className="container-tokenBalance">
              <p style={{fontSize: "12px", textDecoration: "underline dotted" }}>{coin1.symbol}</p>
              <div item>
                <p className="BalanceBoxBody BalanceBoxDetails" style={{fontSize: "12px"}}>
                  {formatReserve(reserves[0])}
                </p>
              </div>
            </div>
            <div className="container-tokenBalance">
              <p style={{fontSize: "12px", textDecoration: "underline dotted" }}>{coin2.symbol}</p>
              <div item >
                <p className="BalanceBoxBody BalanceBoxDetails" style={{fontSize: "12px"}}>
                  {formatReserve(reserves[1])}
                </p>
              </div>
            </div>
          </div>
        </fieldset>
        <button
          loading={loading}
          valid={isButtonEnabled()}
          success={false}
          fail={false}
          onClick={swap}
          className="metadatabuttoncontainer"
        >
          <h6 className="metadatabutton">Swap</h6>
          {/* <img src={swapicon} alt="swap" className="swapicon"></img> */}
        </button>
      </div>      
    </div>
  );
}

export default CoinSwapper;
